import { makeAutoObservable } from 'mobx'
import { contentApi } from '@/api'
import { addCatchNotification } from '@/modules/notifications'
import { ContentData, ISection } from '@/types'

class ContentStore {
  sections: ISection[] = []
  sectionData: ContentData[] | [] = []
  bannerModal = false
  whyNtModal = false
  categoriesModal = false
  learningTypesModal = false

  constructor() {
    makeAutoObservable(this)
  }

  getSections = () =>
    contentApi
      .getSections()
      .then((res) => {
        if (res.success) {
          this.setSection(res.data.sections)
        }
      })
      .catch(addCatchNotification)

  setSectionData = (data: ContentData[]) => {
    this.sectionData = data
  }

  setSection = (sections: ISection[] | []) => {
    this.sections = sections
  }

  setBannerModal = (isOpen: boolean) => {
    this.bannerModal = isOpen
  }

  setWhyNtModal = (isOpen: boolean) => {
    this.whyNtModal = isOpen
  }

  setCategoriesModal = (isOpen: boolean) => {
    this.categoriesModal = isOpen
  }

  setLearningTypesModal = (isOpen: boolean) => {
    this.learningTypesModal = isOpen
  }

  reset() {
    this.bannerModal = false
  }
}

export const contentStore = new ContentStore()
