export enum ChangePasswordEnum {
  CurrentPassword = 'currentPassword',
  NewPassword = 'newPassword',
  ConfirmNewPassword = 'confirmNewPassword',
}

export interface IChangePasswordValues {
  currentPassword: string
  newPassword: string
  confirmNewPassword: string
}
