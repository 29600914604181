import { IBanner, INetworkConfig, IResponse, ISuccessResponse } from '@/types'
import { Endpoints } from '../endpoints'
import { Instance } from '../instance'

const config: INetworkConfig = {
  baseURL: Endpoints.Base,
}

class Banner extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getBanner = (): Promise<IResponse<IBanner>> => this.get(Endpoints.GetBanner)

  editBanner = (params: IBanner): Promise<IResponse<ISuccessResponse>> => this.put(Endpoints.EditBanner, params)
}

export const bannerApi = new Banner(config)
