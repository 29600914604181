export const seoDictionary = {
  title: 'Title',
  description: 'Description',
  headTags: 'Head tags',
  brandName: `Najot Ta'lim`,
  modernProfessions: 'Zamonaviy kasblar',
  headTagsPlaceholder: 'Enter your scripts',
  edit: 'Edit',
  save: 'Saqlash',
  seo: 'SEO',
  confirmSeo: `Kiritilgan ma'lumotlar to'g'riligini tasdiqlaysizmi? `,
  yes: 'Ha',
  no: `Yo'q`,
  changedSuccessfully: `SEO ma’lumotlari muvaffaqqiyatli tarzda o’zgartirildi `,
}
