import { makeAutoObservable } from 'mobx'
import { coursesApi } from '@/api'
import { addCatchNotification } from '@/modules/notifications'
import { ChangeCoursePosition, ICourse, IPagination } from '@/types'

class CoursesStore {
  courses: ICourse[] | [] = []
  courseAddModal = false
  singleCourse: ICourse | null = null
  courseViewModal = false
  totalCourse = 3
  page = 1
  perPage = 10
  changeCoursePosition: ChangeCoursePosition[] = []

  constructor() {
    makeAutoObservable(this)
  }

  getCourses = (params: IPagination) =>
    coursesApi
      .getCourse(params)
      .then((res) => {
        if (res.success) {
          this.setCourses(res.data.courses)
          this.setCourseTotal(res.data.total)
        }
      })
      .catch(addCatchNotification)

  setCourses = (courses: ICourse[]) => {
    this.courses = courses
  }

  setCourseTotal = (total: number) => {
    this.totalCourse = total
  }

  setCourseAddModal = (isOpen: boolean) => {
    this.courseAddModal = isOpen
  }

  setCourseViewModal = (isOpen: boolean) => {
    this.courseViewModal = isOpen
  }

  setSingleCourse = (course: ICourse | null) => {
    this.singleCourse = course
  }

  setPage = (page: number) => {
    this.page = page
  }

  setPerPage = (perPage: number) => {
    this.perPage = perPage
  }

  setChangeCoursePosition = (dragRow: ICourse, position: number) => {
    const lastActionRow = this.changeCoursePosition.at(-1)

    if (lastActionRow && lastActionRow?.id === dragRow.id) {
      lastActionRow.position = position

      return
    }

    this.changeCoursePosition.push({
      id: dragRow.id,
      position,
    })

    const callback =
      dragRow.position > position
        ? (element: ICourse) => {
            if (element.position >= position && element.position < dragRow.position) {
              element.position += 1
            }

            return element
          }
        : (element: ICourse) => {
            if (element.position <= position && element.position > dragRow.position) {
              element.position -= 1
            }

            return element
          }

    this.setCourses(this.courses.map(callback))
    dragRow.position = position
  }

  reset() {
    this.page = 1
    this.perPage = 10
  }
}

export const coursesStore = new CoursesStore()
