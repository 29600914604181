import { ItemType } from './types'

type ConfigMoreBreadcrumbItemsReturnType = {
  first?: ItemType
  last?: ItemType
  menuItems?: ItemType[]
}

export const configMoreBreadcrumbItems = (breadcrumbItems: ItemType[]): ConfigMoreBreadcrumbItemsReturnType => {
  if (breadcrumbItems?.length < 3) {
    return {}
  }

  const [firstItem, ...more] = breadcrumbItems

  return {
    first: firstItem,
    last: more[more.length - 1],
    menuItems: more.slice(0, -1),
  }
}
