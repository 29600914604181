import {
  DiffOutlined,
  FileSearchOutlined,
  FullscreenOutlined,
  MenuFoldOutlined,
  OrderedListOutlined,
} from '@ant-design/icons'
import { constantsDictionary } from './dictionary'
import { ROUTES } from './routes'
import { IMenuItems } from './types'

export const mainMenuList: IMenuItems[] = [
  {
    label: constantsDictionary.content,
    key: ROUTES.home,
    icon: <MenuFoldOutlined />,
  },
  {
    label: constantsDictionary.blogs,
    icon: <FullscreenOutlined />,
    key: ROUTES.blogsPosts,
    children: [
      {
        label: constantsDictionary.blog,
        key: ROUTES.blogsPosts,
        icon: <DiffOutlined />,
      },
      {
        label: constantsDictionary.categories,
        key: ROUTES.blogsCategories,
        icon: <OrderedListOutlined />,
      },
    ],
  },
  {
    label: constantsDictionary.seo,
    key: ROUTES.seo,
    icon: <FileSearchOutlined />,
  },
]
