import { lazy } from 'react'
import { Loading } from '@/components'

const handleCatchChunkError = () => {
  window.location.reload()

  return { default: Loading }
}

export const Home = lazy(() =>
  import('@/pages/Home').then(({ Home }) => ({ default: Home })).catch(handleCatchChunkError),
)

export const AllBlogs = lazy(() =>
  import('@/pages/Blogs/AllBlogs').then(({ AllBlogs }) => ({ default: AllBlogs })).catch(handleCatchChunkError),
)

export const BlogSingle = lazy(() =>
  import('@/pages/Blogs/BlogSingle').then(({ BlogSingle }) => ({ default: BlogSingle })).catch(handleCatchChunkError),
)

export const BlogCategories = lazy(() =>
  import('@/pages/Blogs/BlogCategories')
    .then(({ BlogCategories }) => ({ default: BlogCategories }))
    .catch(handleCatchChunkError),
)

export const BlogCategoriesSingle = lazy(() =>
  import('@/pages/Blogs/BlogCategoriesSingle')
    .then(({ BlogCategoriesSingle }) => ({ default: BlogCategoriesSingle }))
    .catch(handleCatchChunkError),
)

export const Login = lazy(() =>
  import('@/pages/Login').then(({ Login }) => ({ default: Login })).catch(handleCatchChunkError),
)

export const Content = lazy(() =>
  import('@/pages/Content').then(({ Content }) => ({ default: Content })).catch(handleCatchChunkError),
)

export const ContentChange = lazy(() =>
  import('@/pages/ContentChange')
    .then(({ ContentChange }) => ({ default: ContentChange }))
    .catch(handleCatchChunkError),
)

export const TextComment = lazy(() =>
  import('@/pages/ContentChange/EditedSection/TextComment')
    .then(({ TextComment }) => ({ default: TextComment }))
    .catch(handleCatchChunkError),
)

export const VideoComment = lazy(() =>
  import('@/pages/ContentChange/EditedSection/VideoComment')
    .then(({ VideoComment }) => ({ default: VideoComment }))
    .catch(handleCatchChunkError),
)

export const CoursesEdit = lazy(() =>
  import('@/pages/ContentChange/EditedSection/CoursesEdit')
    .then(({ CoursesEdit }) => ({ default: CoursesEdit }))
    .catch(handleCatchChunkError),
)

export const ImagesEdit = lazy(() =>
  import('@/pages/ContentChange/EditedSection/ImagesEdit')
    .then(({ ImagesEdit }) => ({ default: ImagesEdit }))
    .catch(handleCatchChunkError),
)
