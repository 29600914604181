import { Outlet } from 'react-router-dom'
import { Layout as AntdLayout } from 'antd'
import classnamesBind from 'classnames/bind'
import { useBoolean } from 'usehooks-ts'
import { Icons } from '@/components'
import { Content } from './Content'
import { Header } from './Header'
import { Menu } from './Menu'

import styles from './layout.scss'

const cn = classnamesBind.bind(styles)

export const Layout = () => {
  const { value, toggle } = useBoolean(false)
  const siderWidth = value ? 80 : 250

  return (
    <AntdLayout className={cn('layout')} hasSider>
      <AntdLayout.Sider
        trigger={null}
        collapsible
        collapsed={value}
        width={siderWidth}
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <div className={cn('layout__logo', { 'layout__logo--center': value })}>
          <Icons.Logo width={40} />
          {!value && <span className={cn('layout__logo-text')}>Admin</span>}
        </div>

        <div className={cn('layout__menu')}>
          <Menu />
        </div>
      </AntdLayout.Sider>

      <AntdLayout className="site-layout" style={{ marginLeft: siderWidth }}>
        <Header collapsed={value} onCollapsedClick={toggle} />

        <Content>
          <Outlet />
        </Content>
      </AntdLayout>
    </AntdLayout>
  )
}
