import { ReactNode } from 'react'
import classNames from 'classnames/bind'
import { SimpleBreadcrumb, WithButtonBreadcrumb } from './components'
import { BreadcrumbType } from './types'

import styles from './styles.scss'

const cn = classNames.bind(styles)

export const Breadcrumb = ({ items }: BreadcrumbType): ReactNode => {
  const isSimple = items && items.length <= 3

  if (!items) {
    return null
  }

  return (
    <div data-testid={'breadcrumb'}>
      <ul className={cn('breadcrumb__items')}>
        {isSimple ? <SimpleBreadcrumb items={items} /> : <WithButtonBreadcrumb items={items} />}
      </ul>
    </div>
  )
}
