import { makeAutoObservable } from 'mobx'
import { videoCommentsApi } from '@/api'
import { addCatchNotification } from '@/modules/notifications'
import { ChangeVideoCommentPosition, IAddVideoComment, IPagination, IVideoComment } from '@/types'

class VideoCommentStore {
  videoCommentAddModal = false
  videoComments: IVideoComment[] | [] = []
  singleVideoComment: IVideoComment | null = null
  videoCommentViewModal = false
  totalVideoComment = 2
  page = 1
  perPage = 10
  isVideoPlayerOpen = false
  selectedVideo = ''
  changeVideoComment: ChangeVideoCommentPosition[] = []
  videoCommentPosterUrl: string | null = null
  videoCommentPosterModal = false
  isVideoUploading = false
  videoCommentUploadingProgress: number | null = null

  newVideoComment: IAddVideoComment | null = null

  constructor() {
    makeAutoObservable(this)
  }

  setVideoComentPosterUrl = (url: string | null) => {
    this.videoCommentPosterUrl = url
  }

  setVideoCommentPosterModal = (value: boolean) => {
    this.videoCommentPosterModal = value
  }

  getVideoComments = (params: IPagination) =>
    videoCommentsApi
      .getVideoComments(params)
      .then((res) => {
        if (res.success) {
          this.setVideoComments(res?.data?.videoComments)
          this.setVideoCommentTotal(res?.data?.total)
        }

        return res
      })
      .catch(addCatchNotification)

  setVideoComments = (videoComments: IVideoComment[] | []) => {
    this.videoComments = videoComments
  }

  setVideoCommentTotal = (total: number) => {
    this.totalVideoComment = total
  }

  setVideoCommentAddModal = (isOpen: boolean) => {
    this.videoCommentAddModal = isOpen
  }

  setVideoCommentViewModal = (isOpen: boolean) => {
    this.videoCommentViewModal = isOpen
  }

  setSingleVideoComment = (videoComment: IVideoComment | null) => {
    this.singleVideoComment = videoComment
  }

  setIsVideoPlayerOpen = (isOpen: boolean) => {
    this.isVideoPlayerOpen = isOpen
  }

  setSelectedVideo = (url: string) => {
    this.selectedVideo = url
  }

  setPage = (page: number) => {
    this.page = page
  }

  setPerPage = (perPage: number) => {
    this.perPage = perPage
  }

  setChangeVideoComment = (dragRow: IVideoComment, position: number) => {
    const lastActionRow = this.changeVideoComment.at(-1)

    if (lastActionRow && lastActionRow?.id === dragRow.id) {
      lastActionRow.position = position

      return
    }

    this.changeVideoComment.push({
      id: dragRow.id,
      position,
    })

    const callback =
      dragRow.position > position
        ? (element: IVideoComment) => {
            if (element.position >= position && element.position < dragRow.position) {
              element.position += 1
            }

            return element
          }
        : (element: IVideoComment) => {
            if (element.position <= position && element.position > dragRow.position) {
              element.position -= 1
            }

            return element
          }

    this.setVideoComments(this.videoComments.map(callback))
    dragRow.position = position
  }

  setVideoUploading = (value: boolean) => {
    this.isVideoUploading = value
  }

  setVideoCommentUploadingProgress = (progress: number | null) => {
    this.videoCommentUploadingProgress = progress
  }

  setNewVideoComment = (newVideComment: IAddVideoComment | null) => {
    this.newVideoComment = newVideComment
  }

  reset() {
    this.page = 1
    this.perPage = 10
  }
}

export const videoCommentStore = new VideoCommentStore()
