import { makeAutoObservable } from 'mobx'
import { IStaff } from '@/types'

class ProfileStore {
  profileInfo: IStaff | null = null
  showProfileModal = false
  profileEditModal = false
  profilePasswordModal = false

  constructor() {
    makeAutoObservable(this)
  }

  setProfileInfo = (profileInfo: IStaff) => {
    this.profileInfo = profileInfo
  }

  setShowProfileModal = (params: boolean) => {
    this.showProfileModal = params
  }

  setProfileEditModal = (params: boolean) => {
    this.profileEditModal = params
  }

  setProfilePasswordModal = (params: boolean) => {
    this.profilePasswordModal = params
  }

  reset() {
    this.showProfileModal = false
    this.profileEditModal = false
    this.profilePasswordModal = false
  }
}

export const profileStore = new ProfileStore()
