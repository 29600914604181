import { INetworkConfig, IResponse, IStaff, TInitial } from '@/types'
import { Endpoints } from '../endpoints'
import { Instance } from '../instance'

const config: INetworkConfig = {
  baseURL: Endpoints.Base,
}

class AppApi extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getInitial = (): Promise<IResponse<TInitial>> => this.send('post', Endpoints.Initial)

  getProfile = (): Promise<IResponse<IStaff>> => this.get(`${Endpoints.Profile}`)

  logout = (link: string): Promise<IResponse<any>> => this.post(link)
}

export const appApi = new AppApi(config)
