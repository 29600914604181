export const editSectionDictionary = {
  save: 'Saqlash',
  formRequiredMessage: "Bu maydonni ham to'ldiring",
  bannerModalTitle: 'Banner ma’lumotlarini o’zgartirish',
  bannerSuccessSave: 'Banner section bo’yicha o’zgarishlar muvaffaqiyatli saqlandi',
  bannerForm: {
    bannerDiscountLabel: 'Chegirma haqida ma’lumot:',
    bannerDeadline: 'Chegirma qachon tugaydi?',
    bannerColorLabel: 'Bannerga rang tanlang:',
    isVisibleX: 'X - button ko’rinsinmi?',
    isVisibleDate: 'Chegirma vaqti ko’rinsinmi?',
    isVisibleButton: 'Kursni tanlang buttoni ko’rinsinmi?',
    visible: 'Visible',
    inVisible: 'Invisible',
  },
  whyNtModalLabel: 'Nima uchun NT',
  whyNtImgAlt: "Why Najot ta'lim image",
  whyNtRadioLabel: 'Sectionning ko’rinishini tanlang:',
  whyNtSuccessSave: 'O’zgarishlar muvaffaqiyatli saqlandi',
  categoriesModalLabel: 'Categories ma’lumotlarini o’zgartirish',
  categoriesWebUrl: 'Dasturlash uchun link qo’ying:',
  categoriesDesignUrl: 'Dizayn uchun link qo’ying:',
  categoriesMarketingUrl: 'Marketing uchun link qo’ying:',
  categoriesSuccessSave: 'Categories section bo’yicha o’zgarishlar muvaffaqiyatli saqlandi',
  learningTypesModalLable: 'Ta’lim turlari ma’lumotlarini o’zgartirish',
  learningTypesBootcamp: 'Bootcamp uchun link qo’ying:',
  learningTypesStandart: 'Standart uchun link qo’ying:',
  learningTypesSuccesSave: 'Ta’lim turlari section bo’yicha o’zgarishlar muvaffaqiyatli saqlandi',
  textCommentSection: 'Text Comments section',
  textCommentAdd: 'Qo’shish',
  textCommentSave: 'Saqlash',
  dragAndDrop: 'D&D',
  textCommentPhoto: 'Photo',
  textCommentAuthor: 'Author',
  textCommentPosition: 'Lavozim',
  textCommentAction: 'Amallar',
  successSaveTitle: 'Muvaffaqiyatli saqlandi',
  successAddTitle: "Muvaffaqiyatli qo'shildi",
  successWorkTitle: 'Muvaffaqiyatli bajarildi',
  successEditTitle: "Muvaffaqiyatli o'zgartirildi",
  successDeleteTitle: "Muvaffaqiyatli o'chirildi",
}
