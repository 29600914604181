import { makeAutoObservable } from 'mobx'
import { metadataApi } from '@/api'
import { addCatchNotification } from '@/modules/notifications'
import { MetaDataParams, MetaDataResponse } from '@/types'

class MetaDataStore {
  metaData: MetaDataResponse | null = null
  isEditedSuccess = false

  constructor() {
    makeAutoObservable(this)
  }

  setEditedSuccessStatus = (isEditedSuccess: boolean) => {
    this.isEditedSuccess = isEditedSuccess
  }

  setMetaData = (metaData: MetaDataResponse | null) => {
    this.metaData = metaData
  }

  getMetaData = () =>
    metadataApi
      .getMetadata()
      .then((res) => {
        if (res?.success) {
          this.setMetaData(res?.data)
        }
      })
      .catch(addCatchNotification)

  updateMetData = (params: MetaDataParams) =>
    metadataApi
      .updateMetadata(params)
      .then((res) => {
        if (res?.success) {
          this.setEditedSuccessStatus(res?.data)
        }
      })
      .catch(addCatchNotification)

  reset() {
    this.metaData = null
    this.isEditedSuccess = false
  }
}

export const metaDataStore = new MetaDataStore()
