import { AxiosError } from 'axios'
import { homeDoctionary } from '@/pages/Home/dictionary'
import { IResponse, IStaff } from '@/types'
import { stage } from '../api/endpoints'

export const makeFileUrl = (partUrl: string | null): string | undefined => {
  if (!partUrl) {
    return
  }

  if (!stage || !stage.cdnHost) {
    console.error('Error: stage or stage.cdnHost is undefined')

    return
  }

  return new URL(partUrl, stage.cdnHost).href
}

export const makeErrMsg = (error: AxiosError<IResponse<unknown>>): string => {
  if (!error.response?.data) {
    return error.message
  }

  const { error: responseError } = error.response.data

  if (responseError.errMsg instanceof Array) {
    return responseError.errMsg[0]
  }

  return responseError.errMsg
}

export const makeFullName = (user?: IStaff): string => {
  if (user) {
    return `${user?.firstName} ${user?.lastName}`
  }

  return homeDoctionary.unknown
}

export const calcUploadChunkSize = (fileSize: number): number => {
  const size = fileSize / 1024 / 1024

  if (size > 1000) {
    return 8
  }

  if (size > 500) {
    return 6
  }

  if (size > 100) {
    return 4
  }

  if (size > 50) {
    return 2
  }

  return 1
}
