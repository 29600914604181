import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { Button, Col, Form, Input, Modal } from 'antd'
import classnamesBind from 'classnames/bind'
import { useBoolean } from 'usehooks-ts'
import { Loading } from '@/components'
import mainDictionary from '@/dictionary'
import { addCatchNotification, addSuccessNotification } from '@/modules/notifications'
import { useStores } from '@/stores'
import { MetaDataParams } from '@/types'
import { seoBreadcrumb } from './constants'
import { seoDictionary } from './dictionary'

import styles from './seo.scss'

const cn = classnamesBind.bind(styles)

type FormFieldValues = {
  title: string
  desc: string
  metaData: string
}

export const Seo = observer(() => {
  const { value: isFormDisabled, setTrue: setFormDisable, setFalse: setFormEnable } = useBoolean(true)
  const { value: isLoading, setTrue: setLoadingTrue, setFalse: setLoadingFalse } = useBoolean(false)
  const { confirm } = Modal
  const [form] = Form.useForm<FormFieldValues>()
  const { metaDataStore, breadcrumbStore } = useStores()

  const handleFinishForm = (values: MetaDataParams) => {
    metaDataStore
      .updateMetData({ ...values, metaData: values?.title })
      .then(() => {
        addSuccessNotification(seoDictionary.changedSuccessfully)
      })
      .catch(addCatchNotification)

    setFormDisable()
  }

  const handleSeoChangeClick = () => {
    setFormEnable()
  }

  const getMetaData = () => {
    setLoadingTrue()
    metaDataStore.getMetaData().finally(setLoadingFalse)
  }

  const applyDataForInputFields = () => {
    if (!metaDataStore.metaData?.title) {
      return
    }
    form.setFieldsValue({
      title: metaDataStore.metaData.title,
      desc: metaDataStore.metaData?.desc,
    })
  }

  useEffect(() => {
    getMetaData()

    breadcrumbStore.addBreadcrumbs(seoBreadcrumb)

    return () => {
      breadcrumbStore.clearBreadcrumbs()
    }
  }, [])

  useEffect(() => {
    applyDataForInputFields()
  }, [metaDataStore.metaData])

  const handleShowConfirm = () => {
    form.validateFields({ validateOnly: true }).then(() => {
      confirm({
        title: seoDictionary.confirmSeo,
        icon: <ExclamationCircleFilled />,
        okText: seoDictionary.yes,
        cancelText: seoDictionary.no,
        onOk() {
          form.submit()
        },
      })
    })
  }

  return (
    <>
      {isLoading && <Loading />}

      <div className={cn('seo__wrap')}>
        {isFormDisabled && (
          <Button type="primary" onClick={handleSeoChangeClick} data-testid="seo_edit_btn">
            {seoDictionary.edit}
          </Button>
        )}

        <Form
          className={cn('seo__form')}
          autoComplete="off"
          name="category-form"
          layout="vertical"
          onFinish={handleFinishForm}
          form={form}
          validateMessages={{
            required: mainDictionary.formRequiredMessage,
          }}
          disabled={isFormDisabled}
        >
          <Col span={10}>
            <Form.Item label={seoDictionary.title} name="title" rules={[{ required: true }]}>
              <Input placeholder={seoDictionary.brandName} data-testid="seo_title" />
            </Form.Item>

            <Form.Item label={seoDictionary.description} name="desc" rules={[{ required: true }]}>
              <Input placeholder={seoDictionary.modernProfessions} data-testid="seo_description" />
            </Form.Item>
          </Col>

          {!isFormDisabled && (
            <Button type="primary" onClick={handleShowConfirm} data-testid="seo_confirm_btn">
              {seoDictionary.save}
            </Button>
          )}
        </Form>
      </div>
    </>
  )
})
