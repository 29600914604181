import { observer } from 'mobx-react'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import { Button, Col, Layout as AntdLayout, Row } from 'antd'
import classnamesBind from 'classnames/bind'
import { useStores } from '@/stores'
import { Breadcrumb } from '../../Breadcrumb'
import { DropdownProfile } from '../Dropdown'
import { stylesInJs } from './styles'

import styles from './header.scss'

const cn = classnamesBind.bind(styles)

type Props = {
  collapsed: boolean
  onCollapsedClick: () => void
}

export const Header = observer(({ collapsed, onCollapsedClick }: Props) => {
  const { breadcrumbStore } = useStores()

  return (
    <AntdLayout.Header className={cn('header')} style={stylesInJs.header}>
      <Row align="middle">
        <Col span={1}>
          <Button data-testid={'collapse'} type="text" onClick={onCollapsedClick}>
            {collapsed ? (
              <MenuUnfoldOutlined className={cn('header__icon')} />
            ) : (
              <MenuFoldOutlined className={cn('header__icon')} />
            )}
          </Button>
        </Col>

        <Col>
          <Breadcrumb items={breadcrumbStore.breadcrumbs} />
        </Col>

        <Col className={cn('header__logout-btn')} span={5} offset={21}>
          <DropdownProfile />
        </Col>
      </Row>
    </AntdLayout.Header>
  )
})
