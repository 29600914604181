import React, { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import { Menu as AntdMenu, MenuProps } from 'antd'
import { Loading } from '@/components'
import { useStores } from '@/stores'

export const Menu = observer(() => {
  const { appStore } = useStores()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const selectedMenuOpenKeyList = useMemo(() => pathname.split('/'), [pathname])
  const selectedMenuOpenKey = useMemo(
    () => (selectedMenuOpenKeyList.length ? `/${selectedMenuOpenKeyList[1]}` : ''),
    [selectedMenuOpenKeyList, pathname],
  )

  const handleClick: MenuProps['onClick'] = ({ key, domEvent }) => {
    domEvent.preventDefault()
    domEvent.stopPropagation()
    navigate(key)
  }

  if (!appStore.mainMenuItems) {
    return <Loading />
  }

  return (
    <AntdMenu
      data-testid={'antd-menu'}
      theme="dark"
      mode="inline"
      selectedKeys={[pathname]}
      defaultSelectedKeys={[pathname]}
      defaultOpenKeys={[selectedMenuOpenKey]}
      items={appStore.mainMenuItems}
      onClick={handleClick}
    />
  )
})
