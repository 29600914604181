import { INetworkConfig, IResponse, ISections, ISectionsEdit, ISuccessResponse } from '@/types'
import { Endpoints } from '../endpoints'
import { Instance } from '../instance'

const config: INetworkConfig = {
  baseURL: Endpoints.Base,
}

class Content extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getSections = (): Promise<IResponse<ISections>> => this.get(`${Endpoints.Sections}`)

  sectionPositionChange = (params: ISectionsEdit): Promise<IResponse<ISuccessResponse>> =>
    this.put(Endpoints.SectionEdit, params)
}

export const contentApi = new Content(config)
