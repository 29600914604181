import classnamesBind from 'classnames/bind'
import { Icons } from '../icons'

import styles from './loader.scss'

const cn = classnamesBind.bind(styles)

export const Loading = () => (
  <div data-testid={'loading'} className={cn('loader')}>
    <div className={cn('loader__logo')}>
      <span data-testid="loading_icon_wrapper" className={cn('loader__img')}>
        <Icons.Logo />
      </span>
    </div>
  </div>
)
