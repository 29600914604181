import {
  BlogCategorySingleResponseType,
  IAddBlogCategoriesResponse,
  IBlogCategoriesEdit,
  IBlogCategoriesParams,
  IBlogCategoriesRequest,
  IBlogCategoryParams,
  IBlogCategorySingle,
  IBlogEditParams,
  IBlogResponse,
  IBlogsCategoriesResponse,
  IBlogsParams,
  IBlogsResponse,
  IChangeBlogStatusParams,
  IChangeBlogStatusResponse,
  IDeleteBlogCategoryResponse,
  INetworkConfig,
  IResponse,
  ITopBlogResponse,
  TBlogSingleValues,
} from '@/types'
import { getURLParams } from '@/utils/urls'
import { Endpoints } from '../endpoints'
import { Instance } from '../instance'

const config: INetworkConfig = {
  baseURL: Endpoints.Base,
}

class BlogsApi extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getBlogCategories = (params?: IBlogCategoriesRequest): Promise<IResponse<IBlogsCategoriesResponse>> =>
    this.get(Endpoints.PostCategories, params)

  addBlogCategory = (params: IBlogCategoriesParams): Promise<IResponse<IAddBlogCategoriesResponse>> =>
    this.post(Endpoints.PostCategories, params)

  deleteBlogCategory = (params: number): Promise<IResponse<IDeleteBlogCategoryResponse>> =>
    this.delete(`${Endpoints.PostCategories}/${params}`)

  editBlogCategory = (params: IBlogCategoriesEdit): Promise<IResponse<IAddBlogCategoriesResponse>> =>
    this.put(`${Endpoints.PostCategories}/${params.id}`, params)

  getTopBlogs = (): Promise<IResponse<ITopBlogResponse>> => this.get(Endpoints.PostTop)

  getRecommendedBlogs = (): Promise<IResponse<ITopBlogResponse>> => this.get(Endpoints.RecommendedBlogs)

  getBlogs = (params: IBlogsParams): Promise<IResponse<IBlogsResponse>> =>
    this.get(`${Endpoints.AllPosts}/${params.status}`, { params })

  unTopBlog = (params: IChangeBlogStatusParams): Promise<IResponse<IChangeBlogStatusResponse>> =>
    this.put(`${Endpoints.Posts}/${params.id}${Endpoints.UnTop}`)

  toTopBlog = (params: IChangeBlogStatusParams): Promise<IResponse<IChangeBlogStatusResponse>> =>
    this.put(`${Endpoints.Posts}/${params.id}${Endpoints.Top}`)

  recommendBlog = (params: IChangeBlogStatusParams): Promise<IResponse<IChangeBlogStatusResponse>> =>
    this.put(`${Endpoints.Posts}/${params.id}${Endpoints.Recommend}`)

  unRecommendBlog = (params: IChangeBlogStatusParams): Promise<IResponse<IChangeBlogStatusResponse>> =>
    this.put(`${Endpoints.Posts}/${params.id}${Endpoints.UnRecommended}`)

  archiveBlog = (params: IChangeBlogStatusParams): Promise<IResponse<IChangeBlogStatusResponse>> =>
    this.patch(`${Endpoints.Posts}/${params.id}${Endpoints.Archive}`)

  unArchiveBlog = (params: IChangeBlogStatusParams): Promise<IResponse<IChangeBlogStatusResponse>> =>
    this.patch(`${Endpoints.Posts}/${params.id}${Endpoints.UnArchive}`)

  getSingleBlog = (params: string): Promise<IResponse<IBlogResponse>> => this.get(`${Endpoints.Posts}/${params}`)

  addBlog = (params: TBlogSingleValues): Promise<IResponse<IBlogResponse>> =>
    this.post(`${Endpoints.Posts}/${params.status}`, params.data)

  editBlog = (params: IBlogEditParams): Promise<IResponse<IBlogResponse>> =>
    this.put(`${Endpoints.Posts}/${params.id}`, params.data)

  getBlogCategory = (params: IBlogCategoryParams): Promise<IResponse<IBlogCategorySingle>> =>
    this.get(
      getURLParams([Endpoints.PostByCategory, params.status], {
        page: '1',
        perPage: '1000',
        categoryId: params.categoryId.toString(),
      }),
    )

  getBlogCategorySingle = (param: number): Promise<IResponse<BlogCategorySingleResponseType>> =>
    this.get(`${Endpoints.PostCategories}/${param}`)
}

export const blogsApi = new BlogsApi(config)
