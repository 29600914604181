import {
  IChangePasswordRequest,
  ICheckLoginRequest,
  INetworkConfig,
  IResponse,
  IStaff,
  ISuccessResponse,
  IUpdateProfileRequest,
} from '@/types'
import { Endpoints } from '../endpoints'
import { Instance } from '../instance'

const config: INetworkConfig = {
  baseURL: Endpoints.Base,
}

class ProfileApi extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getProfileInfo = (): Promise<IResponse<IStaff>> => this.get(Endpoints.Profile)

  updateProfileInfo = (params: IUpdateProfileRequest): Promise<IResponse<IStaff>> => this.put(Endpoints.Profile, params)

  checkLogin = (params: ICheckLoginRequest): Promise<IResponse<ISuccessResponse>> =>
    this.put(Endpoints.CheckLogin, params)

  changePassword = (params: IChangePasswordRequest): Promise<IResponse<ISuccessResponse>> =>
    this.patch(Endpoints.ChangePassword, params)
}

export const profileApi = new ProfileApi(config)
