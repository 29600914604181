import { RcFile } from 'antd/es/upload'
import { Upload, UploadOptions } from 'tus-js-client'
import { stores } from '@/stores'
import { IBlogBannerResponse, INetworkConfig, IResponse, IUpload, IUploadResponse } from '@/types'
import { calcUploadChunkSize } from '@/utils'
import { Endpoints, stage } from '../endpoints'
import { Instance } from '../instance'

const config: INetworkConfig = {
  baseURL: Endpoints.Base,
}

class UploadsApi extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  uploadBlogBanner = (params: any): Promise<IResponse<IBlogBannerResponse[]>> =>
    this.post(Endpoints.UploadImage, params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

  upload = (formData: FormData): Promise<IResponse<IUpload[]>> => this.post(Endpoints.UploadImage, formData)

  uploadVideoComments = (formData: FormData): Promise<IResponse<IUpload[]>> =>
    this.post(Endpoints.UploadVideo, formData)

  uploadHomework = (formData: FormData, onProgress: UploadOptions): Promise<IResponse<IUploadResponse>> =>
    this.post(Endpoints.UploadImage, formData, {
      onUploadProgress: (e) => {
        // @ts-ignore
        const percent = Math.floor((e.loaded / e.total) * 100)

        //@ts-ignore
        onProgress({ percent })
      },
    })

  uploadVideo = (file: RcFile, filename: string, options: UploadOptions): Upload => {
    const { authStore } = stores
    const accessToken = authStore.token?.accessToken
    const endpoint = stage.apiUrl + Endpoints.VideoUpload

    return new Upload(file, {
      endpoint,
      retryDelays: [0, 3000, 5000, 10000, 20000],
      chunkSize: calcUploadChunkSize(file.size) * 1024 * 1024,
      headers: {
        ...(accessToken && { Authorization: `Bearer ${authStore.token?.accessToken}` }),
      },
      ...options,
    })
  }
}

export const uploadsApi = new UploadsApi(config)
