import { INetworkConfig, IResponse, MetaDataParams, MetaDataResponse } from '@/types'
import { Endpoints } from '../endpoints'
import { Instance } from '../instance'

const config: INetworkConfig = {
  baseURL: Endpoints.Base,
}

class MetadataApi extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getMetadata = (): Promise<IResponse<MetaDataResponse>> => this.get(Endpoints.MetaData)

  updateMetadata = (params: MetaDataParams): Promise<IResponse<boolean>> =>
    this.put(Endpoints.MedaDataSave, { ...params })
}

export const metadataApi = new MetadataApi(config)
