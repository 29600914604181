import { makeAutoObservable } from 'mobx'
import { textCommentApi } from '@/api'
import { addCatchNotification } from '@/modules/notifications'
import { ChangeTextComment, IPagination, ITextComment } from '@/types'

class TextCommentStore {
  textCommentAddModal = false
  textComments: ITextComment[] | [] = []
  singleTextComment: ITextComment | null = null
  textCommentViewModal = false
  totalTextComment = 2
  textCommentPage = 1
  textCommentPerPage = 10
  changeTextComment: ChangeTextComment[] = []

  constructor() {
    makeAutoObservable(this)
  }

  getTextComments = (params: IPagination) =>
    textCommentApi
      .getTextComments(params)
      .then((res) => {
        if (res.success) {
          this.setTextComments(res?.data?.textComments)
          this.setTextCommentTotal(res?.data?.total)
        }

        return res
      })
      .catch(addCatchNotification)

  setTextComments = (textComments: ITextComment[] | []) => {
    this.textComments = textComments
  }

  setTextCommentTotal = (total: number) => {
    this.totalTextComment = total
  }

  setTextCommentAddModal = (isOpen: boolean) => {
    this.textCommentAddModal = isOpen
  }

  setTextCommentViewModal = (isOpen: boolean) => {
    this.textCommentViewModal = isOpen
  }

  setSingleTextComment = (textComment: ITextComment | null) => {
    this.singleTextComment = textComment
  }

  setTextCommentPage = (page: number) => {
    this.textCommentPage = page
  }

  setTextCommentPerPage = (perPage: number) => {
    this.textCommentPerPage = perPage
  }

  setChangeTextComment = (dragRow: ITextComment, position: number) => {
    const lastActionRow = this.changeTextComment.at(-1)

    if (lastActionRow && lastActionRow?.id === dragRow.id) {
      lastActionRow.position = position

      return
    }

    this.changeTextComment.push({
      id: dragRow.id,
      position,
    })

    const callback =
      dragRow.position > position
        ? (element: ITextComment) => {
            if (element.position >= position && element.position < dragRow.position) {
              element.position += 1
            }

            return element
          }
        : (element: ITextComment) => {
            if (element.position <= position && element.position > dragRow.position) {
              element.position -= 1
            }

            return element
          }

    this.setTextComments(this.textComments.map(callback))
    dragRow.position = position
  }
}

export const textCommentStore = new TextCommentStore()
