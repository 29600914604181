import { Link } from 'react-router-dom'
import { MenuProps } from 'antd/es/menu/menu'
import { IMenuItems } from '@/constants'
import { IStaff, MenuItem } from '@/types'

const getItem = (
  label: React.ReactNode,
  key?: React.Key | null,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem =>
  ({
    key,
    icon,
    children,
    label,
    type,
  }) as MenuItem

const handleMainMenuItemClick = (link: string, e: any) => {
  if (e.ctrlKey) {
    e.stopPropagation()
    window.open(`${location?.origin}/#${link}`)
  }
}

export const generateAllMenuItems = (list: IMenuItems[] | undefined, staffInfo: IStaff): MenuProps['items'] =>
  list?.map((item) =>
    getItem(
      <Link className="sidebar-links" onClick={handleMainMenuItemClick.bind(null, item.key)} to={item.key}>
        {item?.label}
      </Link>,
      item.key,
      item.icon,
      (item.children && generateAllMenuItems(item.children, staffInfo)) || undefined,
    ),
  )

export const convertMenuToBreadcrumb = (menuItems: IMenuItems[], parent?: IMenuItems['key']): IMenuItems[] =>
  menuItems.reduce<IMenuItems[]>(
    (prevValue, { label, key, children, icon }) => [
      ...prevValue,
      { label, key, icon, ...(parent ? { parent } : []) },
      ...(children ? convertMenuToBreadcrumb(children, key) : []),
    ],
    [],
  )
