import { ICategories, IEditEduType, INetworkConfig, IResponse, ISuccessResponse } from '@/types'
import { Endpoints } from '../endpoints'
import { Instance } from '../instance'

const config: INetworkConfig = {
  baseURL: Endpoints.Base,
}

class Categories extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getCategories = (): Promise<IResponse<ICategories>> => this.get(Endpoints.GetCategories)

  editCategories = (params: IEditEduType[]): Promise<IResponse<ISuccessResponse>> =>
    this.put(Endpoints.EditCategories, params)
}

export const categoriesApi = new Categories(config)
