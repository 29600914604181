export const gamificationDictionary = {
  byGroup: 'Guruh bo`yicha',
  byAction: 'Amal bo`yicha',
  assistantName: 'Yordamchi ustoz ismi',
  studentCount: 'O`quvchi soni',
  startDate: 'Boshlangan sana',
  endDate: 'Tugagan sana',
  actionsCount: 'Amallar soni',
  level: 'Bosqich',
  settings: 'Sozlash',
  willEndDate: 'Tuga(ydi)gan sana',
  actions: 'Amallar',
  groupsCount: 'Guruhlar soni',
  levelsCount: 'Bosqichlar soni',
  xpForLevel: "Ushbu levelga o'tish uchun chegara XP miqdori",
  levelAddSuccess: 'Bosqich muvaffaqiyatli qo`shildi',
  levelDeleteSuccess: 'Bosqich muvaffaqiyatli o`chirildi',
  levelEditSuccess: 'Bosqich muvaffaqiyatli o`zgartirildi',
  deeds: 'Amallar',
  setup: 'Sozlash',
  addAction: 'Amal qo`shish',
  chooseMethod: 'Tizimdagi metodlardan birini tanlang',
  editSuccessFullyActionLevel: 'Amal bosqichi muvaffaqiyatli o`zgartirildi',
  addActionSuccess: 'Amal muvaffaqiyatli qo`shildi',
  addActionLevel: 'Amalga level qo`shish',
  addSuccessfullyActionLevel: 'Action level muvaffaqiyatli qo`shildi',
  confirmModalTitle: 'Siz chindan ham ushbu levelni o’chirmoqchimisiz?',
  deleteSuccessfully: 'Amal bosqichi muvaffaqiyatli o`chirildi',
  sureToDelete: 'Siz chindan ham ushbu levelni o`chirmoqchimisiz?',
  successEdit: 'Muvaffaqiyatli saqlandi',
  select: 'Iltimos tanlang',
  successfullyAddedStudentAward: 'O`quvchiga mukofot muvaffaqiyatli qo`shildi',
  iResults: 'natijalari',
  methodNameLengthPatternMessage: 'Nomi 5 ta va undan ko`p belgidan iborat bo`lishi kerak',
  editDeeds: 'Amal o’zgartirish',
  successfullyEditDeed: 'Amal muvaffaqiyatli yangilandi',
  staff: 'Xodim',
  activeSchedules: 'Aktiv',
  addLevelToDeed: 'Amalga level qo`shish',
  topXp: '86-100 XP',
  topSilver: '86-100 Kumush',
  satisfactoryXp: '71-85 XP',
  satisfactorySilver: '71-85 Kumush',
  goodXp: '60-70 XP',
  goodSilver: '60-70 Kumush',
  awfulXp: '1-59 XP',
  awfulSilver: '1-59 Kumush',
  cameOnTimeXp: 'O`z vaqtida kelgandagi XP',
  cameOnTimeSilver: 'O`z vaqtida kelgandagi Kumush',
  lateXp: 'Kechga qolgandagi XP',
  lateSilver: 'Kechga qolgandagi Kumush',
  resultMonitoring: "Yig'ilgan natijalar monitoringi",
  participateLesson: 'Darsda qatnashgani uchun',
  doHomework: 'Uyga vazifa bajargani uchun',
  participateExam: 'Imtihonda qatnashgani uchun',
  passExam: "Imtihondan o'tgani uchun",
  addLevelToAction: 'Amalga level qo`shish',
  xp: 'Xp',
  silver: 'Kumush',
  highLevel: '86-100',
  mediumLevel: '71-85',
  lowerLevel: '60-70',
  lowLevel: '1-59',
  cameOnTime: 'O`z vaqtida kelgandagi',
  late: 'Kechikkandagi',
  successfullyAdded: 'Bosqich muvaffaqiyatli qo`shildi',
}
