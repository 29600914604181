export const academicDictionary = {
  filterName: {
    label: 'Ism-familiya',
    placeholder: 'Qidirish',
  },
  filterRole: {
    label: 'Rol tanlash',
    defaultValue: 'Barchasi',
  },
  filterWorkStatus: {
    label: 'Ish holati',
    defaultValue: 'Ishlayabdi',
  },
  filterStatus: {
    label: 'Holati',
    defaultValue: 'Faol',
  },
  working: 'Ishlayabdi',
  notWorking: "Bo'shagan",
  workStatus: 'Ishlashi',
  workingAcademic: 'Ishlayabdi',
  vacantAcademic: "Bo'shagan",
  unBlock: 'Blokdan chiqarish',
  profileGenerated: 'Profil yasaldi',
  profileGenerate: 'Profil yasash',
  male: 'Erkak',
  female: 'Ayol',
  workingTeacher: 'Ishlayabdi',
  invalidFileNotificationMessage: "Rasm .jpg yoki .jpeg formatda bo'lishi va hajmi 5MB dan oshmasligi kerak",
  firstNamePlaceholder: 'Ism kiriting',
  lastNamePlaceholder: 'Familiya kiriting',
  loginPlaceholder: 'Login kiriting',
  errWrongFormat: "Noto'g'ri format",
  iPhoneNumber: 'Telefon raqami',
  phoneNumberPlaceholder: '998XXXXXXX',
  phoneNumberAddon: '+',
  gender: 'Jinsi',
  chooseGender: 'Jinsni tanlang',
  typeWorkStatus: 'Ishlash holatini tanlang',
  photoSingle: 'Rasmi',
  upload500x500: 'Yuklash  500x500',
  activeUser: 'faol',
  disactiveUser: 'faol emas',
  hasNotProfile: "profili yo'q",
  blockUserConfirm: 'Siz ushbu academikni bloklamoqchimisiz?',
  groupInfo: 'Guruh ma`lumoti',
  academicEditSuccessfully: 'Akademik muvaffaqiyatli o`zgartirildi',
  chooseBranch: 'Fillialni tanlang',
  activeSchedules: 'Faol',
  other: 'Boshqa',
  ustoz: 'Ustoz',
  lessonDays: 'Dars kunlari',
  noProfile: 'Profili yo`q',
}
