import { useEffect, useState } from 'react'
import { useLocalStorage } from 'usehooks-ts'
import { useStores } from '@/stores'
import { TokenType } from '@/types'

export const useBootstrap = () => {
  const { authStore } = useStores()
  const [isInitiated, setIsInitiated] = useState(true)
  const [accessToken] = useLocalStorage<TokenType['accessToken']>('accessToken', '')
  const [refreshToken] = useLocalStorage<TokenType['refreshToken']>('refreshToken', '')

  const setToken = () => {
    if (accessToken && refreshToken) {
      authStore.setIsAuth(true)
      authStore.setToken({
        accessToken,
        refreshToken,
      })
    }
  }

  const getAppConfigs = async () => {
    try {
      await setToken()

      setIsInitiated(false)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getAppConfigs()
  }, [])

  return [isInitiated]
}
