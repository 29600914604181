import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { LogoutOutlined } from '@ant-design/icons'
import { Modal, Typography } from 'antd'
import classnamesBind from 'classnames/bind'
import { useLocalStorage } from 'usehooks-ts'
import { resetStores, useStores } from '@/stores'
import { TokenType } from '@/types'
import { logOutDictionary } from './dictionary'

import styles from './logout.scss'

const cn = classnamesBind.bind(styles)

export const LogOut: React.FC = observer(() => {
  const { appStore } = useStores()
  const [, setAccessToken] = useLocalStorage<TokenType['accessToken']>('accessToken', '')
  const [, setRefreshToken] = useLocalStorage<TokenType['refreshToken']>('refreshToken', '')
  const [isModalOpen, setIsModalOpen] = useState(false)

  const showModal = () => {
    setIsModalOpen(true)
  }

  useEffect(() => {
    appStore.getProfile()
  }, [])

  const handleOk = () => {
    resetStores()
    setAccessToken('')
    setRefreshToken('')
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <div>
      <span onClick={showModal} data-testid={'logout'} className={cn('logout')}>
        <LogoutOutlined />
        <Typography.Text data-testid={'exit'} className={cn('logout__title')}>
          {logOutDictionary.exit}
        </Typography.Text>
      </span>
      <Modal title={logOutDictionary.exit} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <span>{logOutDictionary.logOut}</span>
      </Modal>
    </div>
  )
})
