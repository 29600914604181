export const ROUTES = {
  home: '/',
  signIn: '/signin',
  admins: '/admins',
  profile: '/profile',
  contentChange: '/content/edit',
  textComment: '/content/edit/text-comments',
  videoComment: '/content/edit/video-comments',
  courses: '/content/edit/courses',
  images: '/content/edit/images',

  blogs: '/blogs',
  blogsPosts: '/blogs/posts',
  blogsCategories: '/blogs/categories',
  blogCategoriesSingle: '/blogs/categories/:categoryId',
  blogsUnArchiveAdd: '/blogs/unarchive/add',
  blogsArchiveAdd: '/blogs/archive/add',
  blogUnArchiveEdit: '/blogs/archive/:blogId/edit',
  blogArchive: '/blogs/archive',
  blogUnArchive: '/blogs/unarchive',
  post: '/blogs/posts',
  seo: '/seo',
  blog: '/blog',
} as const
