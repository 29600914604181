export const blogSingleDictionary = {
  activeBlogs: 'Active Blogs',
  add: 'Add',
  addBlog: 'Add blog',
  addBlogCategory: "Category qo'shish",
  addRecommended: 'Add recommended',
  addTopBlog: 'Add top blog',
  answer: 'Answer',
  anyActions: 'Any actions',
  archivate: 'Archivate',
  archivateConfirm: 'Are you sure for archivate this blog?',
  archiveBlogs: 'Archive Blogs',
  archiveSuccess: 'Blog archived successfully',
  author: 'Muallif',
  authorPlaceholder: 'Muallifni kiriting',
  back: 'Qaytish',
  bannerImage: 'Banner image',
  blog: 'Blog',
  blogEdited: 'Blog edited successfully',
  blogPoster: 'Blog uchun poster yuklang:',
  blogPosterTitle: 'Blog poster',
  blogs: 'Blogs',
  blogSuccessAdd: '{status} blog added successfully',
  blogTheme: 'Blog mavzusi',
  blogUnTop: 'Blog un topped',
  categories: 'Categories',
  categoryBgcolor: 'Category background color',
  categoryName: 'Category name',
  categoryTextColor: 'Category text color',
  chooseBlog: 'Blogni tanlang',
  confirmRecommended: 'Are you sure recommend this blog?',
  confirmToTop: 'Are you sure to top this blog?',
  confirmUnRecommended: 'Are you sure for remove this blog from recommended list?',
  create: 'Create',
  createdAt: 'Category created date',
  edit: 'Edit',
  editBlog: 'Edit blog',
  editBlogCategory: "Categoryni o'zgartirish",
  invalidVideoNotificationMessage: "Videoning formati mp4 va webm bo'lishi kerak",
  noContent: 'Hozirda platformada hech qanday blog mavjud emas',
  numeration: 'Num',
  order: 'No',
  publishedDate: 'Published date',
  question: 'Question',
  recommended: 'Recommended',
  recommendedBlog: 'Recommended Blog',
  recommendedBlogs: 'Recommended Blogs',
  removeRecommended: 'Remove recommended',
  requiredFile: 'Fayl yuklamagansiz!',
  search: 'Search',
  searchError: 'Afsuski qidiruvga mos natija topilmadi',
  selectCategory: 'Categoryni tanlash',
  shortlyDesc: 'Qisqacha izoh',
  shouldFill: "Barcha fieldlarni to'ldirish shart",
  shouldUpload: 'Editorda Faqat jpg png jpeg va giflarni yuklash mumkin',
  smthWrong: 'Ooops something went wrong!',
  sort: 'Sort',
  successBlogCategoryDelete: 'Blog category deleted successfully',
  successBlogCategoryEdit: 'Blog category edited successfully',
  successfullyAddBlogCategory: 'Blog category added successfully',
  sure: "Rostdan ham o'chirmmoqchimisiz",
  topBlog: 'Blog topped',
  topBlogs: 'Top blogs',
  toTop: 'To top',
  unArchivateConfirm: 'Are you sure for unarchivate this blog?',
  unArrchiveSuccess: 'Blog unarchived successfully',
  unrecommendedBlog: 'Unrecommended Blog',
  unTop: 'Un top',
  unTopConfirm: 'Are you sure for un top this blog?',
  uploadedImage: 'Uploaded image',
  viewSite: 'View on site',
  seoTitle: 'Metadata Title (SEO)',
  seoDescription: 'Metadata Description (SEO)',
}
