export const studentDictionary = {
  id: 'ID',
  name: 'Ism',
  surname: 'Familiya',
  fatherName: 'Otasining ismi',
  searchInputLabel: 'Ism yoki HHID',
  searchInputPlaceholder: 'Qidiruv',
  searchSelectLabel: "Yo'nalish",
  searchSelectDefaultValue: 'Barchasi',
  tableId: 'HHID',
  tableFullname: 'Ism-familiya',
  holyHopeStatus: 'HH holati',
  more: 'Batafsil',
  sms: 'Sms',
  smsHistory: 'Sms tarixi',
  sentSms: "Jo'natilgan smslar",
  smsSend: "Sms jo'natish",
  group: 'Guruh',
  level: 'Bosqich',
  passwordGenerated: 'Parol yasaldi',
  successChangeLogin: "Siz ushbu o'quvchini loginini o'zgartirdingiz!",
  sentNumber: "Jo'natilgan raqami",
  sentAuthor: "Jo'natuvchi",
  toWhom: 'Kimga',
  phoneNumber: 'Telefon raqam',
  smsNot: "Bu studentning smslari yo'q",
  smsSended: "Sms jo'natildi",
  sistem: 'Tizim',
  seeResult: 'Natijalarni ko`rish',
  allXp: "Jami yig'ilgan XP:",
  allSilver: "Jami yig'ilgan Kumushlar:",
  notActive: 'Faol emas',
  confirmChangePassword: "Siz ushbu studentning parolini o'zgartirmoqchimisiz?",
  confirmBlock: 'Siz ushbu studentni bloklamoqchimisiz?',
  confirmUnblock: 'Siz ushbu studentni blokdan chiqarmoqchimisiz?',
  blockedSuccessFully: 'Student muvaffaqiyatli bloklandi',
  unblockSuccessFully: 'Student muvaffaqiyatli blokndan chiqarildi',
  closes: 'Yaqinlari',
  available: 'Bor',
  unavailable: "Yo'q",
  groupSuccessfullyAddedToStudent: 'Guruh muvaffaqiyatli qo`shildi',
}
