import { makeAutoObservable, observable } from 'mobx'
import { SignInParamsType, StaffInfoType, TokenType } from '@/types'
import { authApi } from '../../api'

class AuthStore {
  isAuth: boolean | null = null
  token: TokenType | null = null
  userInfo: StaffInfoType | null = null

  constructor() {
    makeAutoObservable(this, {
      isAuth: observable,
    })
  }

  getSignIn = ({ login, password }: SignInParamsType) =>
    authApi.getSignIn({ login, password }).then((res) => {
      if (res.success) {
        this.setUserInfo(res.data.staffInfo)
        this.setToken({
          accessToken: res.data.accessToken,
          refreshToken: res.data.refreshToken,
        })
        this.setIsAuth(true)
      }

      return res
    })

  setUserInfo = (userInfo: StaffInfoType) => {
    this.userInfo = userInfo
  }

  setToken = (token: TokenType) => {
    this.token = token
  }

  setIsAuth = (isAuth: boolean) => {
    this.isAuth = isAuth
  }

  reset = () => {
    this.isAuth = null
    this.token = null
    this.userInfo = null
    window.localStorage.clear()
  }
}

export const authStore = new AuthStore()
