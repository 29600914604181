import type { RcFile } from 'antd/es/upload'
import { UploadOptions } from 'tus-js-client'
import { uploadsApi } from '@/api'
import { FileFolders, IUpload, IUploadResponse } from '@/types'

export const uploadFile = async (file: RcFile, fileFolder: FileFolders): Promise<IUpload[]> => {
  try {
    const formData = new FormData()

    formData.append('folder', fileFolder)
    formData.append('files', file)

    const { data } = await uploadsApi.upload(formData)

    return data
  } catch (error: any) {
    console.error(error)

    return Promise.reject(error)
  }
}

export const uploadHomework = async (
  file: RcFile,
  fileFolder: FileFolders,
  onProgress: UploadOptions,
): Promise<IUploadResponse> => {
  const formData = new FormData()

  formData.append('fileFolder', fileFolder)
  formData.append('files', file)

  const { data } = await uploadsApi.uploadHomework(formData, onProgress)

  return data
}

export const uploadVideoComments = async (file: RcFile, fileFolder: FileFolders): Promise<IUpload[]> => {
  try {
    const formData = new FormData()

    formData.append('folder', fileFolder)
    formData.append('files', file)

    const { data } = await uploadsApi.uploadVideoComments(formData)

    return data
  } catch (error: any) {
    console.error(error)

    return Promise.reject(error)
  }
}
