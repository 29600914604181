import { IIMages, IImagesChangePosition, ImageParamsType, INetworkConfig, IResponse } from '@/types'
import { Endpoints } from '../endpoints'
import { Instance } from '../instance'

const config: INetworkConfig = {
  baseURL: Endpoints.Base,
}

class Images extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getImages = (): Promise<IResponse<IIMages>> => this.get(Endpoints.Images)

  addImages = (params: ImageParamsType): Promise<IResponse<IIMages>> =>
    this.post(Endpoints.Images, { url: params.url, placeHolder: params.placeHolder })

  deleteImages = (id: number): Promise<IResponse<IIMages>> => this.delete(`${Endpoints.Images}/${id}`)

  imagesChangePosition = (params: IImagesChangePosition): Promise<IResponse<IIMages>> =>
    this.put(`${Endpoints.Images}/${params.id}/position`, {
      to: params.to,
    })
}

export const imagesApi = new Images(config)
