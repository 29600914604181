import { NotificationArgsProps } from 'antd'
import { AxiosError } from 'axios'

export enum NotificationMessageType {
  Success = 'success',
  Error = 'error',
  Info = 'info',
  Warning = 'warning',
}

export type NotificationParams = Omit<NotificationArgsProps, 'message'> & {
  message: unknown | Error | AxiosError | NotificationArgsProps['message']
  messageType?: NotificationMessageType
}
