import React, { useEffect, useMemo } from 'react'
import { observer } from 'mobx-react'
import { UserOutlined } from '@ant-design/icons'
import { Avatar, Dropdown, MenuProps, Typography } from 'antd'
import classNames from 'classnames/bind'
import { useStores } from '@/stores'
import { makeFileUrl, makeFullName } from '@/utils'
import { LogOut } from '../LogOut'
import { Profile } from '../Profile'

import styles from './dropdown-profile.scss'

const cn = classNames.bind(styles)

export const DropdownProfile: React.FC = observer(() => {
  const { appStore } = useStores()

  useEffect(() => {
    appStore.getProfile()
  }, [])

  const items: MenuProps['items'] = useMemo(
    () => [
      {
        key: '1',
        label: appStore?.staffInfo && <Profile />,
      },
      {
        key: '2',
        label: <LogOut />,
      },
    ],
    [appStore.staffInfo],
  )

  return (
    <Dropdown data-testid={'dropdown'} className={cn('dropdown-profile')} menu={{ items }} placement="bottomLeft">
      <div className={cn('dropdown-wrapper')}>
        <Typography.Title level={5}>{appStore?.staffInfo && makeFullName(appStore?.staffInfo)}</Typography.Title>
        <Avatar
          src={appStore?.staffInfo && makeFileUrl(appStore?.staffInfo?.photo)}
          style={{ backgroundColor: '#BC8E5B' }}
          icon={<UserOutlined />}
        />
      </div>
    </Dropdown>
  )
})
