import { observer } from 'mobx-react'
import { EditOutlined, UserOutlined } from '@ant-design/icons'
import { Avatar, Button, Card, Col, Modal, Row, Typography } from 'antd'
import classNames from 'classnames/bind'
import { useStores } from '@/stores'
import { makeFileUrl } from '@/utils'
import { profileDictionary } from '../dictionary'

import styles from '../profile.scss'

const cn = classNames.bind(styles)

export const ProfileShowModal = observer(() => {
  const { profileStore, appStore } = useStores()
  const { setShowProfileModal, showProfileModal, setProfileEditModal, setProfilePasswordModal } = profileStore
  const { staffInfo } = appStore

  const handleOk = () => {
    setShowProfileModal(false)
  }

  const handleCancel = () => {
    setShowProfileModal(false)
  }

  const handleEditProfile = () => {
    setProfileEditModal(true)
    setShowProfileModal(false)
  }

  const handleEditPassword = () => {
    setProfilePasswordModal(true)
    setShowProfileModal(false)
  }

  return (
    <Modal
      title={profileDictionary.profileShowModalTitle}
      open={showProfileModal}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
    >
      <Card className={cn('profile__card')}>
        <Row className={cn('profile__info')}>
          <Typography.Title className={cn('profile__heading')} level={4}>
            {profileDictionary.profileInfo}
          </Typography.Title>
          <Button onClick={handleEditProfile} type="primary" icon={<EditOutlined />} />
        </Row>
        <Row className={cn('profile__show-info')}>
          <Col span={6}>
            <Avatar size={64} src={staffInfo?.photo && makeFileUrl(staffInfo?.photo)} icon={<UserOutlined />} />
          </Col>
          <Col span={18}>
            <Row className={cn('profile__info-body')}>
              <Typography.Title className={cn('profile__heading')} level={5}>
                {profileDictionary.profileFirstName}
              </Typography.Title>
              {staffInfo?.firstName}
            </Row>
            <Row className={cn('profile__info-body')}>
              <Typography.Title className={cn('profile__heading')} level={5}>
                {profileDictionary.profileLastName}
              </Typography.Title>
              {staffInfo?.lastName}
            </Row>
            <Row className={cn('profile__info-body')}>
              <Typography.Title className={cn('profile__heading')} level={5}>
                {profileDictionary.profileLogin}
              </Typography.Title>
              {staffInfo?.login}
            </Row>
          </Col>
        </Row>
      </Card>

      <Card className={cn('profile__card')}>
        <Row className={cn('profile__info')}>
          <Typography.Title className={cn('profile__heading')} level={4}>
            {profileDictionary.profilePassword}
          </Typography.Title>
          <Button onClick={handleEditPassword} type="primary" icon={<EditOutlined />} />
        </Row>

        <Row className={cn('profile__info-body')}>
          <Typography.Title className={cn('profile__heading')} level={4}>
            {profileDictionary.profilePasswordShow}
          </Typography.Title>
        </Row>
      </Card>
    </Modal>
  )
})
