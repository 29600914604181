import { Link } from 'react-router-dom'
import classNames from 'classnames/bind'
import { ItemType } from '../types'

import styles from './breadcrumbs.scss'

const cn = classNames.bind(styles)

export const Item = ({ title, path, children }: ItemType) => (
  <li className={cn('breadcrumb-items__item')}>
    {path ? (
      <Link className={cn('breadcrumb-items__link')} to={path}>
        {children ? children : title}
      </Link>
    ) : (
      <span data-testid={'breadcrumb-item-span'} className={cn('breadcrumb-items__element')}>
        {children ? children : title}
      </span>
    )}
  </li>
)
