export const branchOfficeDictionary = {
  addBranchOffice: 'Filial qo’shish',
  editBranchOffice: 'Filial o’zgartirish',
  addedBranchOffice: (name: string) => `Yangi ofis qo'shildi ${name}`,
  branchOfficeName: 'Filial nomi',
  courseName: 'Kurs nomi',
  currentOfficeNameErrorMore3chartsMessage: "3 dan ortiq belgilarni ko'rsating",
  currentOfficeNameErrorRequiredMessage: "Tizim uchun filial nomlarini ko'rsating",
  delete: "O'chirish",
  deleteBranchOffice: (name: string) => `Filialni "${name}" olib tashlang`,
  edit: 'Tahrirlash',
  holyHopeBranchOffice: 'HolyHope filiali',
  holyHopeOfficeIdFieldErrorRequired: "Ro'yxatdan filial nomlarini tanlang",
  holyHopesBranchOfficeName: 'Filial nomi [HolyHopedagi]',
  openDeleteModalMessage: "Filial olib tashlash uchun ko'rsatilmagan",
  branchName: 'Fillial nomi',
}
