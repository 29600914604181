import 'dayjs/locale/ru'
import 'antd/dist/reset.css'

import { FC } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { observer, Provider } from 'mobx-react'
import { ConfigProvider } from 'antd'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import timezone from 'dayjs/plugin/timezone'
import updateLocale from 'dayjs/plugin/updateLocale'
import utc from 'dayjs/plugin/utc'
import { Loading } from '@/components'
import { months } from '@/constants/utils'
import { Router } from '@/router'
import { stores, useStores } from '@/stores'
import { Theme as AntdTheme } from '@/styles/theme'
import { useBootstrap } from './useBootstrap'

dayjs.extend(utc)
dayjs.extend(updateLocale)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)

dayjs.tz.setDefault('Asia/Tashkent')

dayjs.updateLocale('en', {
  months,
})

import '@/styles/global.scss'

export const App: FC = observer(() => {
  const { authStore } = useStores()
  const [isInitiated] = useBootstrap()

  if (isInitiated) {
    return <Loading />
  }

  return (
    <Provider {...stores}>
      <ConfigProvider csp={{ nonce: 'najod' }} theme={AntdTheme}>
        <BrowserRouter>
          <Router isAuth={authStore.isAuth} />
        </BrowserRouter>
      </ConfigProvider>
    </Provider>
  )
})
