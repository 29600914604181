import { appStore } from './app'
import { authStore } from './auth'
import { blogsStore } from './blogs'
import { breadcrumbStore } from './breadcrumb'
import { contentStore } from './content'
import { coursesStore } from './courses'
import { metaDataStore } from './metadata'
import { profileStore } from './profile'
import { textCommentStore } from './textComment'
import { videoCommentStore } from './videoComment'
export const stores = {
  appStore,
  authStore,
  breadcrumbStore,
  contentStore,
  profileStore,
  textCommentStore,
  coursesStore,
  videoCommentStore,
  blogsStore,
  metaDataStore,
}

export const resetStores = () => {
  appStore.reset()
  authStore.reset()
  contentStore.reset()
  breadcrumbStore.reset()
  profileStore.reset()
  coursesStore.reset()
  videoCommentStore.reset()
  metaDataStore.reset()
}
