export const profileDictionary = {
  settings: 'Settings',
  profileShowModalTitle: 'Profile o’zgartirish',
  profileInfo: 'Profile ma’lumotlari',
  profileFirstName: 'Ismi:',
  profileLastName: 'Familiyasi:',
  profileLogin: 'Login:',
  profilePassword: 'Profile password',
  profilePasswordShow: '* * * * * * * *',
  profileEdit: 'Profile ma’lumotlarini o’zgartirish',
  profileEditFormValidation: "Bu maydonni ham to'ldiring",
  writeFirstName: 'Ismingizni kiriting:',
  writeLastName: 'Familiyangizni kiriting:',
  writeLogin: 'Loginingizni kiriting',
  usernameAlreadyExists: 'Foydalanuvchi nomi allaqachon mavjud',
  enterCurrentPassword: 'Amaldagi parolingizni kiriting:',
  newPassword: 'Yangi parolingizni kiriting:',
  newPasswordReset: 'Yangi parolingizni takror kiriting:',
  confirmPassword: 'Please confirm your password!',
  errorPassword: 'The two passwords that you entered do not match!',
  profileUploadImg: 'Rasm faylni ko’rsating:',
  uploadedImage: 'Uploaded image',
  shouldFill: "Barcha fieldlarni to'ldirish shart",
  successChangeProfile: 'Profile ma’lumotlari muvaffaqiyatli o’zgartirildi',
  successfullyChangedPassword: 'Profile passwordi muvaffaqiyatli o’zgartirildi',
  save: 'Saqlash',
}
