import { IEditEduType, IEduTypes, INetworkConfig, IResponse, ISuccessResponse } from '@/types'
import { Endpoints } from '../endpoints'
import { Instance } from '../instance'

const config: INetworkConfig = {
  baseURL: Endpoints.Base,
}

class EduTypes extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getEduTypes = (): Promise<IResponse<IEduTypes>> => this.get(Endpoints.EduTypes)

  editEduTypes = (params: IEditEduType[]): Promise<IResponse<ISuccessResponse>> =>
    this.put(Endpoints.EditEduTypes, params)
}

export const eduTypesApi = new EduTypes(config)
