import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Modal, Upload, UploadFile, UploadProps } from 'antd'
import { RcFile } from 'antd/es/upload'
import { UploadButton } from '@/components'
import mainDictionary from '@/dictionary'
import { addCatchNotification, addSuccessNotification } from '@/modules/notifications'
import { useStores } from '@/stores'
import { FileFolders, IUpdateProfileRequest } from '@/types'
import { makeFileUrl, uploadFile } from '@/utils'
import { UPLOAD_ACCEPT } from '../constants'
import { profileDictionary } from '../dictionary'

export const ProfileEditModal = () => {
  const [loading, setLoading] = useState(false)
  const [bannerFileList, setBannerFileList] = useState<UploadFile[]>([])
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [form] = Form.useForm()
  const { appStore, profileStore } = useStores()
  const { staffInfo } = appStore
  const { profileEditModal, setProfileEditModal, setShowProfileModal } = profileStore

  const handleFormFinish = async (values: IUpdateProfileRequest) => {
    setLoading(true)
    const profileImage = bannerFileList[0]

    if (profileImage.originFileObj) {
      const data = await uploadFile(profileImage.originFileObj, FileFolders.UserProfile)

      values.photo = data[0]?.path
    }

    await appStore
      .updateProfileInfo(values)
      .then(() => {
        addSuccessNotification(mainDictionary.successEditTitle, {
          description: profileDictionary.successChangeProfile,
        })
        handleCancel()
      })
      .catch(addCatchNotification)

    setLoading(true)
  }

  const handleOk = () => {
    form.submit()
  }

  const handleCancel = () => {
    setProfileEditModal(false)
    setShowProfileModal(true)
  }

  const handleBeforeUpload = () => false

  const handleBannerChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setBannerFileList(newFileList)
  }

  const handlePreview = async (file: UploadFile) => {
    let src = file.url as string

    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader()

        reader.readAsDataURL(file.originFileObj as RcFile)
        reader.onload = () => resolve(reader.result as string)
      })
    }
    const image = new Image()

    setPreviewImage(src)
    setPreviewOpen(true)
    image.src = src
  }

  const handleCancelUplaodImgModal = () => {
    setPreviewOpen(false)
  }

  useEffect(() => {
    if (staffInfo) {
      form.setFieldsValue({
        firstName: staffInfo?.firstName,
        lastName: staffInfo?.lastName,
        login: staffInfo?.login,
        photo: makeFileUrl(staffInfo?.photo),
      })
      setBannerFileList([
        {
          uid: '-1',
          name: 'image.png',
          status: 'done',
          url: makeFileUrl(staffInfo?.photo),
        },
      ])

      return
    }

    handleCancel()
  }, [])

  return (
    <Modal
      open={profileEditModal}
      confirmLoading={loading}
      title={profileDictionary.profileEdit}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button type="primary" key="ok" onClick={handleOk}>
          {profileDictionary.save}
        </Button>,
      ]}
    >
      <Form
        form={form}
        onFinish={handleFormFinish}
        layout="vertical"
        validateMessages={{
          required: profileDictionary.profileEditFormValidation,
        }}
      >
        <Form.Item label={profileDictionary.profileUploadImg} rules={[{ required: true }]} name="photo">
          <Upload
            maxCount={1}
            onPreview={handlePreview}
            beforeUpload={handleBeforeUpload}
            onChange={handleBannerChange}
            fileList={bannerFileList}
            listType="picture-circle"
            accept={UPLOAD_ACCEPT}
          >
            {bannerFileList.length === 0 && <UploadButton />}
          </Upload>
        </Form.Item>
        <Form.Item rules={[{ required: true }]} name="firstName" label={profileDictionary.writeFirstName}>
          <Input placeholder={profileDictionary.writeFirstName} />
        </Form.Item>

        <Form.Item rules={[{ required: true }]} name="lastName" label={profileDictionary.writeLastName}>
          <Input placeholder={profileDictionary.writeLastName} />
        </Form.Item>

        <Form.Item
          rules={[
            {
              required: true,
              whitespace: true,
            },
          ]}
          name="login"
          label={profileDictionary.writeLogin}
          validateTrigger="onBlur"
        >
          <Input type="text" placeholder={profileDictionary.writeLogin} />
        </Form.Item>
      </Form>

      <Modal
        title={profileDictionary.uploadedImage}
        open={previewOpen}
        footer={null}
        onCancel={handleCancelUplaodImgModal}
      >
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </Modal>
  )
}
