import { makeAutoObservable } from 'mobx'
import { blogsApi, uploadsApi } from '@/api'
import { addCatchNotification } from '@/modules/notifications'
import {
  BlogCategorySingleResponseType,
  IBlogCategoriesEdit,
  IBlogCategoriesParams,
  IBlogCategoriesRequest,
  IBlogCategoryParams,
  IBlogCategorySingle,
  IBlogEditParams,
  IBlogModalParams,
  IBlogResponse,
  IBlogsCategoriesResponse,
  IBlogsParams,
  IBlogsResponse,
  IChangeBlogStatusParams,
  ITopBlogResponse,
  TBlogSingleValues,
} from '@/types'

class BlogsStore {
  blogCategories: IBlogsCategoriesResponse | null = null
  blogCategoriesModal = false
  topBlogs: ITopBlogResponse | null = null
  recommendedBlogs: ITopBlogResponse | null = null
  blogs: IBlogsResponse | null = null
  archiveBlogs: IBlogsResponse | null = null
  modalState: IBlogModalParams | null = null
  blogSingle: IBlogResponse | null = null
  blogCategory: IBlogCategorySingle | null = null
  blogArchivedCategory: IBlogCategorySingle | null = null
  blogPosterUrl: string | null = null
  blogPosterModal = false
  isAvailable = false
  blogCategorySingle: BlogCategorySingleResponseType | null = null

  constructor() {
    makeAutoObservable(this)
  }

  setIsAvailable = (isAvailable: boolean) => {
    this.isAvailable = isAvailable
  }

  setBlogCategories = (data: IBlogsCategoriesResponse | null) => {
    this.blogCategories = data
  }

  setTopBlogs = (data: ITopBlogResponse | null) => {
    this.topBlogs = data
  }

  setRecommendedBlog = (data: ITopBlogResponse | null) => {
    this.recommendedBlogs = data
  }

  setArchiveBlogs = (data: IBlogsResponse | null) => {
    this.archiveBlogs = data
  }

  setBlogSingle = (data: IBlogResponse | null) => {
    this.blogSingle = data
  }

  setBlogCategory = (data: IBlogCategorySingle | null) => {
    this.blogCategory = data
  }

  setBlogArchiveCategory = (data: IBlogCategorySingle | null) => {
    this.blogArchivedCategory = data
  }

  getBlogCategories = (params?: IBlogCategoriesRequest) =>
    blogsApi
      .getBlogCategories(params)
      .then((res) => {
        if (res.success) {
          this.setBlogCategories(res.data)
        }

        return res
      })
      .catch(addCatchNotification)

  editBlogCategories = (params: IBlogCategoriesEdit) => blogsApi.editBlogCategory(params)

  getTopBlogs = () =>
    blogsApi
      .getTopBlogs()
      .then((res) => {
        if (res.success) {
          this.setTopBlogs(res.data)
        }

        return res
      })
      .catch(addCatchNotification)

  getRecommendedBlogs = () =>
    blogsApi
      .getRecommendedBlogs()
      .then((res) => {
        if (res.success) {
          this.setRecommendedBlog(res.data)
        }

        return res
      })
      .catch(addCatchNotification)

  uploadBlogBanner = (params: any) => uploadsApi.uploadBlogBanner(params)

  setBlogs = (blogs: IBlogsResponse | null) => {
    this.blogs = blogs
  }

  getBlogs = (params: IBlogsParams) =>
    blogsApi
      .getBlogs(params)
      .then((res) => {
        if (res.success) {
          this.setBlogs(res.data)
        }

        return res
      })
      .catch(addCatchNotification)

  getArchiveBlogs = (params: IBlogsParams) =>
    blogsApi
      .getBlogs(params)
      .then((res) => {
        if (res.success) {
          this.setArchiveBlogs(res.data)
        }
      })
      .catch(addCatchNotification)

  addBlog = (params: TBlogSingleValues) => blogsApi.addBlog(params)

  getSingleBlog = (params: string) =>
    blogsApi
      .getSingleBlog(params)
      .then((res) => {
        if (res.success) {
          this.setBlogSingle(res.data)
        }

        return res
      })
      .catch(addCatchNotification)

  getActiveBlogCategory = (params: IBlogCategoryParams) =>
    blogsApi.getBlogCategory(params).then((res) => {
      if (res.success) {
        this.setBlogCategory(res.data)
      }

      return res
    })

  getArchivedBlogCategory = (params: IBlogCategoryParams) =>
    blogsApi.getBlogCategory(params).then((res) => {
      if (res.success) {
        this.setBlogArchiveCategory(res.data)
      }

      return res
    })

  setSingleBlogCategory = (data: BlogCategorySingleResponseType) => {
    this.blogCategorySingle = data
  }

  getSingleBlogCategory = (param: number) =>
    blogsApi
      .getBlogCategorySingle(param)
      .then((res) => {
        if (res.success) {
          this.setSingleBlogCategory(res.data)
        }
      })
      .catch(addCatchNotification)

  editBlog = (params: IBlogEditParams) => blogsApi.editBlog(params)

  archiveBlog = (params: IChangeBlogStatusParams) => blogsApi.archiveBlog(params)

  unArchiveBlog = (params: IChangeBlogStatusParams) => blogsApi.unArchiveBlog(params)

  toggleBlogModal = (params: IBlogModalParams) => {
    this.modalState = params
  }

  addBlogCategory = (params: IBlogCategoriesParams) => blogsApi.addBlogCategory(params)

  unTopBlog = (params: IChangeBlogStatusParams) => blogsApi.unTopBlog(params)

  toTopBlog = (params: IChangeBlogStatusParams) => blogsApi.toTopBlog(params)

  recommendBlog = (params: IChangeBlogStatusParams) => blogsApi.recommendBlog(params)

  unRecommendBlog = (params: IChangeBlogStatusParams) => blogsApi.unRecommendBlog(params)

  deleteBlogCategory = (params: number) => blogsApi.deleteBlogCategory(params)

  toggleBlogCategoriesModal = (status: boolean) => {
    this.blogCategoriesModal = status
  }

  setBlogPosterUrl = (url: string | null) => {
    this.blogPosterUrl = url
  }

  setBlogPosterModal = (value: boolean) => {
    this.blogPosterModal = value
  }

  reset = () => {
    this.blogCategories = null
    this.blogCategoriesModal = false
    this.topBlogs = null
    this.recommendedBlogs = null
    this.blogs = null
    this.archiveBlogs = null
    this.modalState = null
    this.blogSingle = null
    this.isAvailable = false
  }
}

export const blogsStore = new BlogsStore()
