import { INetworkConfig, IResponse, ISignInResponse, SignInParamsType } from '@/types'
import { Endpoints } from '../endpoints'
import { Instance } from '../instance'

const config: INetworkConfig = {
  baseURL: Endpoints.Base,
}

class AuthApi extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getSignIn = ({ login, password }: SignInParamsType): Promise<IResponse<ISignInResponse>> =>
    this.post(Endpoints.SignIn, { login, password })
}

export const authApi = new AuthApi(config)
