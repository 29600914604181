export const controlDictionary = {
  rolesName: 'Rol nomi',
  makeAt: 'Yasalgan vaqti',
  blocked: 'Bloklangan',
  user: 'Foydalanuvchi',
  role: 'Rol',
  userAdd: 'Foydalanuvchi yasash',
  userName: 'Foydalanuvchi nomi',
  editUser: 'Foydalanuchini o`zgartirish',
  confirmDelete: 'Rostdan ham o`chirmoqchimisiz?',
  confirmLogin: 'Rostdan ham loggini yangilamoqchimisiz?',
  newPasswordIs: 'Yangi parol',
  newPassword: 'Yangi parol',
  firstName: 'Ism',
  lastName: 'Familiya',
  editRole: 'o`zgartirish',
  successfullyCreateRole: 'Rol muvaffaqiyatli qo`shildi',
  sureDelete: 'Rostdan ham ushbu rol ni o`chirmoqchimisiz?',
  shouldFillAllFields: 'Barcha maydonni to`ldirish shart',
  editSuccessfully: 'Rol muvaffaqiyatli o`zgartirildi)',
  roleAdd: 'Rol yasash',
  usersCount: 'Foydalanuvchilar soni',
  roleName: 'Rol nomi',
  roleType: 'Rol tanlash',
  profilePic: 'Profil rasmi',
  isWorking: 'Ishlayabdi',
  notProfile: 'Profil mavjud emas',
  invalidFileNotificationMessage2: "Rasm .jpg yoki .jpeg formatda bo'lishi va hajmi 2MB dan oshmasligi kerak",
  selectGender: 'Jinsini tanlang',
  selectRole: 'Rol tanlang',
  phoneFormatCheckerError: 'Telefon raqamini to`g`ri formatda kiriting',
  userInfo: 'Foydalanuvchi ma`lumoti',
  userEditSuccessfully: 'Foydalanuvchi muvaffaqiyatli o`zgartirildi',
  staffs: 'Xodimlar',
  academic: 'Akademik',
  enterRoleName: 'Rol uchun nom kiriting...',
  sortByAction: 'Amal bo`yicha',
  sortByRole: 'Rol bo`yicha',
  sortByUser: 'Foydalanuvchi bo`yicha',
  search: 'Qidiruv',
  sortByDate: 'Sana bo`yicha',
  sortByTime: 'Vaqt bo`yicha',
  dateTime: 'Vaqt',
  owner: 'Bajaruvchi',
  ownerRole: 'Bajaruvchi roli',
  actionName: 'Amal nomi',
  aboutAction: 'Amal haqida',
  givenPermissions: 'Berilgan huquqlar',
  infoTitle: 'Qurilma ma`lumotlari',
  adminLogin: 'Admin panel logini',
  adminPassword: 'Admin panel paroli',
  ipAddress: 'IP manzili',
  enter: 'Kirish',
  quit: 'Chiqish',
  invalidLink: 'Link kiritish kerak',
  successfullyDeleted: 'Muvaffaqiyatli o`chirildi',
  wentWrong: 'Nimadir xato ketdi',
  successfullyEdited: 'Muvaffaqiyatli o`zgartirildi',
  successfullyAdded: 'Muvaffaqiyatli qo`shildi',
  largeSizeOfImage: 'Foydalanuvchi surati 2 MB dan katta bo’lmasligi lozim',
  imageRequired: 'Foydalanuvchi surati yuklash shart',
  workStatus: 'Ishlashi',
}

export const RoleTypes = ['Yo`q', 'Xodim', 'Akademik']
export const WorkTypesList = ['Yo`q', 'Ishlayapti', 'Bo`shagan']
