import { makeAutoObservable } from 'mobx'
import { ItemType } from '@/types'

class BreadcrumbStore {
  breadcrumbs: ItemType[] | null = null

  constructor() {
    makeAutoObservable(this)
  }

  addBreadcrumbs = (breadcrumbs: ItemType[]) => {
    this.breadcrumbs = breadcrumbs
  }

  clearBreadcrumbs = () => {
    this.breadcrumbs = null
  }

  addBreadcrumb = (breadcrumb: ItemType) => {
    if (!this.breadcrumbs) {
      this.breadcrumbs = []
    }

    this.breadcrumbs.push(breadcrumb)
  }

  removeBreadcrumb = () => {
    this.breadcrumbs?.pop()
  }

  reset = () => {
    this.breadcrumbs = null
  }
}

export const breadcrumbStore = new BreadcrumbStore()
