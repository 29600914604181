import React, { Fragment } from 'react'
import classNames from 'classnames/bind'
import { SEPARATOR_CHAR } from '../constants'
import { BreadcrumbType } from '../types'
import { Item } from './Item'

import styles from './breadcrumbs.scss'

const cn = classNames.bind(styles)

export const SimpleBreadcrumb = ({ items }: BreadcrumbType): React.ReactElement => (
  <>
    {items?.map((item, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Fragment key={`${item}-${index}`}>
        {index !== 0 && <li className={cn('simple-breadcrumb__items separator')}>{SEPARATOR_CHAR}</li>}
        <Item {...item} />
      </Fragment>
    ))}
  </>
)
