export const assistantsDictionary = {
  holliHopStatus: 'HolliHop status',
  searchPlaceholderAssistants: 'Assistant qidirish',
  searchError: 'Afsuski qidiruvga mos natija topilmadi',
  modulesCount: 'Modullari soni',
  teachersCount: "O'qituvchilar soni",
  blockStaffConfirmAssistants: 'Siz ushbu assistantni bloklamoqchimisiz?',
  blockReason: 'Foydalanuvchi bloklandi',
  teachersPlaceholder: "O'qituvchilar biriktiring",
  modulesPlaceholder: 'Modullar biriktiring',
  usernameAlreadyExists: 'Foydalanuvchi nomi allaqachon mavjud',
  deletePhotoConfirm: 'Siz ushbu rasmni o’chirmoqchimisiz?',
}
