import { Layout as AntdLayout } from 'antd'
import classnamesBind from 'classnames/bind'

import styles from './content.scss'

const cn = classnamesBind.bind(styles)

type Props = {
  children: React.ReactNode
}

export const Content = ({ children }: Props) => (
  <AntdLayout.Content className={cn('content')}>{children}</AntdLayout.Content>
)
