import { Suspense } from 'react'
import { Navigate, useRoutes } from 'react-router-dom'
import { Loading } from '@/components'
import { ROUTES } from '@/constants'
import { Layout } from '@/modules/Layout'
import { Seo } from '@/pages/Seo'
import {
  AllBlogs,
  BlogCategories,
  BlogCategoriesSingle,
  BlogSingle,
  Content,
  ContentChange,
  CoursesEdit,
  ImagesEdit,
  Login,
  TextComment,
  VideoComment,
} from './lazy'
import { ProtectedRoutes } from './ProtectedRoutes'
import { PublicRoutes } from './PublicRoutes'

type Props = {
  isAuth: boolean | null
}

export const Router = ({ isAuth }: Props) =>
  useRoutes([
    {
      path: ROUTES.home,
      element: <ProtectedRoutes isAuth={isAuth} />,
      children: [
        {
          path: ROUTES.home,
          element: <Layout />,
          children: [
            {
              path: ROUTES.home,
              element: (
                <Suspense fallback={<Loading />}>
                  <Content />
                </Suspense>
              ),
              index: true,
            },
            {
              path: ROUTES.contentChange,
              element: (
                <Suspense fallback={<Loading />}>
                  <ContentChange />
                </Suspense>
              ),
            },
            {
              path: ROUTES.textComment,
              element: (
                <Suspense fallback={<Loading />}>
                  <TextComment />
                </Suspense>
              ),
            },
            {
              path: ROUTES.videoComment,
              element: (
                <Suspense fallback={<Loading />}>
                  <VideoComment />
                </Suspense>
              ),
            },
            {
              path: ROUTES.courses,
              element: (
                <Suspense fallback={<Loading />}>
                  <CoursesEdit />
                </Suspense>
              ),
            },
            {
              path: ROUTES.images,
              element: (
                <Suspense fallback={<Loading />}>
                  <ImagesEdit />
                </Suspense>
              ),
            },
            {
              path: ROUTES.seo,
              element: (
                <Suspense fallback={<Loading />}>
                  <Seo />
                </Suspense>
              ),
            },
            {
              path: ROUTES.blogs,
              children: [
                {
                  path: ROUTES.blogsPosts,
                  element: (
                    <Suspense fallback={<Loading />}>
                      <AllBlogs />
                    </Suspense>
                  ),
                },
                {
                  path: ROUTES.blogsCategories,
                  element: (
                    <Suspense fallback={<Loading />}>
                      <BlogCategories />
                    </Suspense>
                  ),
                },
                {
                  path: ROUTES.blogsUnArchiveAdd,
                  element: (
                    <Suspense fallback={<Loading />}>
                      <BlogSingle />
                    </Suspense>
                  ),
                },
                {
                  path: ROUTES.blogsArchiveAdd,
                  element: (
                    <Suspense fallback={<Loading />}>
                      <BlogSingle />
                    </Suspense>
                  ),
                },
                {
                  path: ROUTES.blogUnArchiveEdit,
                  element: (
                    <Suspense fallback={<Loading />}>
                      <BlogSingle />
                    </Suspense>
                  ),
                },
                {
                  path: ROUTES.blogCategoriesSingle,
                  element: (
                    <Suspense fallback={<Loading />}>
                      <BlogCategoriesSingle />
                    </Suspense>
                  ),
                },
              ],
            },
            {
              path: '*',
              element: <Navigate to={ROUTES.home} />,
            },
          ],
        },
      ],
    },
    {
      path: ROUTES.signIn,
      element: <PublicRoutes isAuth={isAuth} />,
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={<Loading />}>
              <Login />
            </Suspense>
          ),
        },
      ],
    },
  ])
