import {
  ChangeVideoCommentPosition,
  IAddVideoComment,
  IEditVideoComment,
  INetworkConfig,
  IPagination,
  IResponse,
  ISuccessResponse,
  IUploadVideo,
  IVideoComments,
  OnUploadProgressType,
} from '@/types'
import { Endpoints } from '../endpoints'
import { Instance } from '../instance'

const config: INetworkConfig = {
  baseURL: Endpoints.Base,
}

class VideoComments extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getVideoComments = (params: IPagination): Promise<IResponse<IVideoComments>> =>
    this.get(Endpoints.VideoComments, { params })

  uploadVideoComment = (params: FormData, onUploadProgress: OnUploadProgressType): Promise<IResponse<IUploadVideo[]>> =>
    this.post(Endpoints.UploadVideo, params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    })

  addVideoComment = (params: IAddVideoComment): Promise<IResponse<ISuccessResponse>> =>
    this.post(Endpoints.VideoComments, params)

  editVideoComment = (params: IEditVideoComment): Promise<IResponse<ISuccessResponse>> =>
    this.put(`${Endpoints.VideoComments}/${params?.id}`, params)

  deleteVideoComments = (id: number): Promise<IResponse<ISuccessResponse>> =>
    this.delete(`${Endpoints.VideoComments}/${id}`)

  editVideoCommentPosition = (params: ChangeVideoCommentPosition[]): Promise<IResponse<ISuccessResponse>> =>
    this.put(Endpoints.UpdatedVideoCommentPosition, params)
}

export const videoCommentsApi = new VideoComments(config)
