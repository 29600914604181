import {
  ChangeCoursePosition,
  ICourseAddCategories,
  ICourseAddEduTypes,
  ICourseDisciplinesGet,
  ICoursePost,
  ICourses,
  IEditCourse,
  INetworkConfig,
  IPagination,
  IResponse,
  ISuccessResponse,
} from '@/types'
import { Endpoints } from '../endpoints'
import { Instance } from '../instance'

const config: INetworkConfig = {
  baseURL: Endpoints.Base,
}

class Courses extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getCourse = (params: IPagination): Promise<IResponse<ICourses>> => this.get(Endpoints.Courses, { params })

  addCourses = (params: ICoursePost): Promise<IResponse<ICourses>> => this.post(Endpoints.Courses, params)

  deleteCourse = (id: number): Promise<IResponse<ICourseDisciplinesGet>> => this.delete(`${Endpoints.Courses}/${id}`)

  editCourse = (params: IEditCourse): Promise<IResponse<ICourseDisciplinesGet>> =>
    this.put(`${Endpoints.Courses}/${params?.id}`, params)

  getDiscplines = (): Promise<IResponse<ICourseDisciplinesGet>> => this.get(Endpoints.CourseDiscpline)

  editCoursesPosition = (params: ChangeCoursePosition[]): Promise<IResponse<ISuccessResponse>> =>
    this.put(Endpoints.UpdateCoursePosition, params)

  getCourseCategories = (): Promise<IResponse<ICourseAddCategories>> => this.get(Endpoints.GetCategories)

  getCourseEduTypes = (): Promise<IResponse<ICourseAddEduTypes>> => this.get(Endpoints.EduTypes)
}

export const coursesApi = new Courses(config)
