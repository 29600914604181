import { INetworkConfig, IResponse, ISuccessResponse, IWhyNt } from '@/types'
import { Endpoints } from '../endpoints'
import { Instance } from '../instance'

const config: INetworkConfig = {
  baseURL: Endpoints.Base,
}

class WhyNt extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getWhyNt = (): Promise<IResponse<IWhyNt>> => this.get(Endpoints.GetWhyNt)

  editWhyNt = (params: IWhyNt): Promise<IResponse<ISuccessResponse>> => this.put(Endpoints.EditWhyNt, params)
}

export const whyNtApi = new WhyNt(config)
