import { observer } from 'mobx-react'
import { SettingOutlined } from '@ant-design/icons'
import { Typography } from 'antd'
import classNames from 'classnames/bind'
import { useStores } from '@/stores'
import { profileDictionary } from './dictionary'
import { ProfileEditModal } from './ProfileEditModal'
import { ProfilePasswordEditModal } from './ProfilePasswordEditModal'
import { ProfileShowModal } from './ProfileShowModal'

import styles from './profile.scss'

const cn = classNames.bind(styles)

export const Profile = observer(() => {
  const { profileStore } = useStores()
  const { setShowProfileModal, showProfileModal, profileEditModal, profilePasswordModal } = profileStore

  const handleShowProfileModal = () => {
    setShowProfileModal(true)
  }

  return (
    <>
      <span className={cn('profile')} onClick={handleShowProfileModal}>
        <SettingOutlined />
        <Typography.Text className={cn('profile__title')}>{profileDictionary.settings}</Typography.Text>
      </span>

      {showProfileModal && <ProfileShowModal />}
      {profileEditModal && <ProfileEditModal />}
      {profilePasswordModal && <ProfilePasswordEditModal />}
    </>
  )
})
