import { IStage } from '@/types'

const appStage = process.env.REACT_APP_STAGE as string

export enum Endpoints {
  Base = '/api/admin',
  Initial = '/initial',
  SignIn = 'auth/sign-in',
  Root = '/',
  UploadImage = '/upload/image',
  UploadVideo = '/upload/video',
  Profile = '/users/profile',
  Upload = 'api/upload',
  CheckLogin = '/check-login',
  ChangePassword = '/users/change-password',
  VideoUpload = '/tus/files',
  Sections = '/sections',
  SectionEdit = '/sections/save',
  EduTypes = '/edu-types',
  EditEduTypes = '/edu-types/urls',
  GetCategories = '/categories',
  EditCategories = '/categories/urls',
  GetWhyNt = '/school-benefits',
  EditWhyNt = '/school-benefits/save',
  GetBanner = '/banners',
  EditBanner = '/banners/save',
  TextComments = '/text-comments',
  UpdateTextComments = '/text-comments/positions',
  VideoComments = '/video-comments',
  UpdatedVideoCommentPosition = '/video-comments/positions',
  Images = '/images',
  ImagesChangePosition = '/images/{id}/position',
  Courses = '/courses',
  CourseDiscpline = '/courses/disciplines',
  UpdateCoursePosition = '/courses/positions',

  PostCategories = '/post-categories',
  PostTop = '/posts/top',
  RecommendedBlogs = '/posts/recommended',
  AllPosts = '/posts/all',
  Posts = '/posts',
  Post = '/post',
  UnTop = '/untop',
  Top = '/top',
  Recommend = '/recommended',
  UnRecommended = '/unrecommended',
  Archive = '/archive',
  UnArchive = '/unarchive',
  Faqs = '/faqs',
  Faq = '/faq',
  FaqMove = '/faq/move',
  PostByCategory = '/posts/by-category',
  VideoCommentMove = '/video-comment/move',
  VideoComment = '/video-comment',
  MetaData = '/meta-data',
  MedaDataSave = '/meta-data/save',
}

type StagesType = Record<string, IStage>

const stages: StagesType = {
  dev: {
    nwsUrl: 'http://217.30.162.22:1300',
    apiUrl: 'http://217.30.162.22:3010',
    cdnHost: 'https://dev.cdn.najotedu.uz/',
  },
  prod: {
    nwsUrl: 'https://najottalim.uz',
    apiUrl: 'https://nws.main.najottalim.uz',
    cdnHost: 'https://nws.cdn.najottalim.uz/',
  },
  test: {
    nwsUrl: 'https://nws.najotedu.uz',
    apiUrl: 'https://nws.main.najotedu.uz',
    cdnHost: 'https://ntop-nwsminio.najotedu.uz/',
  },
}

export const stage = stages[appStage]
