export const faceIdDeviceDictionary = {
  faceIdDevice: 'Hikvision qurilmalari',
  deviceName: 'Qurilma nomi',
  type: 'Turi',
  faceIdDeviceAdd: 'Hikvision qurilma qo`shish',
  faceIdDeviceEdit: 'Hikvision qurilmani o`zgartirish',
  enterType: 'kiriting',
  faceIdScreens: 'Hikvisionda bloklanganlar',
  deviceType: 'Qurilma turi',
  deviceLogin: 'Qurilma admin login',
  devicePassword: 'Qurilma admin paneli paroli',
  diviceIpAddress: 'Qurilma ip manzili',
  sureDeleteFaceIdDevice: 'Rostdan ham o`chirmoqchimisiz?',
  example: '111.111.111.111:80',
}
