import { Link } from 'react-router-dom'
import classNames from 'classnames/bind'
import { Icons } from '@/components'
import { ItemType } from '../types'
import { breadcrumbLastItems } from './constants'

import styles from './menu.scss'

const cn = classNames.bind(styles)

type Props = {
  items: ItemType[]
}

export const Menu = ({ items }: Props) => {
  const [firstItem, ...lastItems] = items

  return (
    <ul data-testid={'menu-in-btn-breadcrumb'} className={cn('menu-block__list')}>
      <li className={cn('menu-link')}>
        {firstItem?.path ? (
          <Link className={cn('breadcrumb-items__link')} to={firstItem?.path}>
            {firstItem?.title}
          </Link>
        ) : (
          firstItem?.title
        )}
      </li>
      {lastItems.map(({ title, path, children }, index) => (
        <li className={cn('menu-link menu-last__link')} key={`menu-link-${path}`}>
          {path ? (
            <Link
              className={cn('breadcrumb-items__link menu-last__items')}
              to={path}
              style={breadcrumbLastItems(index)}
            >
              <Icons.Breadcrumb className={cn('breadcrumb__icon')} />
              {children ? children : title}
            </Link>
          ) : (
            title
          )}
        </li>
      ))}
    </ul>
  )
}
