import { Button, Form, Input, Modal } from 'antd'
import { profileApi } from '@/api'
import mainDictionary from '@/dictionary'
import { addCatchNotification, addSuccessNotification } from '@/modules/notifications'
import { useStores } from '@/stores'
import { profileDictionary } from '../dictionary'
import { ChangePasswordEnum, IChangePasswordValues } from '../types'

export const ProfilePasswordEditModal = () => {
  const { profileStore } = useStores()
  const [form] = Form.useForm()
  const { profilePasswordModal, setProfilePasswordModal, setShowProfileModal } = profileStore

  const handleOk = () => {
    form.submit()
  }

  const handleCancel = () => {
    setProfilePasswordModal(false)
    setShowProfileModal(true)
  }

  const handleFormFinish = (values: IChangePasswordValues) => {
    profileApi
      .changePassword(values)
      .then((res) => {
        if (res.success) {
          addSuccessNotification(mainDictionary.successEditTitle, {
            description: profileDictionary.successfullyChangedPassword,
          })
          handleCancel()
        }
      })
      .catch(addCatchNotification)
  }

  return (
    <Modal
      open={profilePasswordModal}
      title={profileDictionary.profileEdit}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button type="primary" key="ok" onClick={handleOk}>
          {profileDictionary.save}
        </Button>,
      ]}
    >
      <Form layout="vertical" form={form} onFinish={handleFormFinish}>
        <Form.Item
          label={profileDictionary.enterCurrentPassword}
          name={ChangePasswordEnum.CurrentPassword}
          rules={[{ required: true }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label={profileDictionary.newPassword}
          name={ChangePasswordEnum.NewPassword}
          rules={[{ required: true, min: 8 }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label={profileDictionary.newPasswordReset}
          name={ChangePasswordEnum.ConfirmNewPassword}
          dependencies={['newPassword']}
          hasFeedback
          rules={[
            {
              required: true,
              message: profileDictionary.confirmPassword,
            },
            ({ getFieldValue }) => ({
              // eslint-disable-next-line @typescript-eslint/naming-convention
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve()
                }

                return Promise.reject(new Error(profileDictionary.errorPassword))
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  )
}
