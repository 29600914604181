export const generalDictionary = {
  okText: 'Ha',
  cancelText: "Yo'q",
  login: 'Kirish',
  noInternet: 'Internet bilan biron muammo bor.',
  somethingWentWrong: "Biror narsa noto'g'ri ketdi",
  fullname: 'Ism-familiya',
  password: 'Parol',
  action: 'Harakatlar',
  active: 'Faol',
  disActive: 'Faol emas',
  photo: 'Rasm',
  status: 'Holat',
  users: 'Foydalanuvchilar',
  students: 'O`quvchilar',
  teachers: "O'qituvchilar",
  nope: 'Yo’q',
  add: 'Qo’shish',
  save: 'Saqlash',
  yes: 'Ha',
  no: "Yo'q",
  cancel: 'Bekor qilish',
  unknown: "Noma'lum",
  branches: 'Filiallar',
  all: 'Barchasi',
  userPhoto: 'Foydalanuvchi surati',
  order: '#',
  filter: 'Filterlash',
}
