import {
  ChangeCoursePosition,
  IAddTextComment,
  INetworkConfig,
  IPagination,
  IPostTextComment,
  IResponse,
  ISuccessResponse,
  ITextComment,
  ITextComments,
} from '@/types'
import { Endpoints } from '../endpoints'
import { Instance } from '../instance'

const config: INetworkConfig = {
  baseURL: Endpoints.Base,
}

class TextComment extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getTextComments = (params: IPagination): Promise<IResponse<ITextComments>> =>
    this.get(Endpoints.TextComments, { params })

  deleteTextComments = (id: number): Promise<IResponse<ISuccessResponse>> =>
    this.delete(`${Endpoints.TextComments}/${id}`)

  editTextComment = (params: IPostTextComment): Promise<IResponse<ITextComment>> =>
    this.put(`${Endpoints.TextComments}/${params?.id}`, params)

  addTextComment = (params: IAddTextComment): Promise<IResponse<ITextComment>> =>
    this.post(Endpoints.TextComments, params)

  editTextCommentPosition = (params: ChangeCoursePosition[]): Promise<IResponse<ISuccessResponse>> =>
    this.put(Endpoints.UpdateTextComments, params)
}

export const textCommentApi = new TextComment(config)
