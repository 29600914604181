export const enum UserStatus {
  NEW = 1,
  ACTIVE = 2,
  BLOCKED = 3,
}

export enum SectionTypes {
  Dynamic = 1,
  Static = 2,
  StaticPlus = 3,
}

export const enum Role {
  STUDENT = 'STUDENT',
  ASSISTANT = 'ASSISTANT',
  TEACHER = 'TEACHER',
}

export const enum FileFolders {
  UserProfile = 'user/profile',
  VideoComments = 'videos-comments',
  Images = 'images',
  Courses = 'courses',
  Posts = 'posts',
}

export const enum DataStatus {
  ACTIVE = 1,
  INACTIVE = 2,
  ARCHIVED = 3,
}

export const enum SocketEvents {
  VideoUpdate = 'video_update',
  VideoReload = 'video_reload',
  VideoUpdateProgress = 'video_update_progress',
}

export const enum Statuses {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
}
