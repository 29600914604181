import { makeAutoObservable } from 'mobx'
import { MenuProps } from 'antd/es/menu/menu'
import { mainMenuList } from '@/constants'
import { addCatchNotification } from '@/modules/notifications'
import { generateAllMenuItems } from '@/stores/app/utils'
import { IStaff, ItemType, IUpdateProfileRequest, TInitial } from '@/types'
import { appApi, profileApi } from '../../api'

export class AppStore {
  initialParams: Partial<TInitial> | null = null
  mainMenuItems: MenuProps['items'] | null = null
  breadcrumbList: ItemType[] | [] = []
  staffInfo: IStaff | null = null

  constructor() {
    makeAutoObservable(this)
  }

  getProfile = () =>
    appApi
      .getProfile()
      .then((res) => {
        if (res.success) {
          this.staffInfo = res.data
          this.mainMenuItems = generateAllMenuItems(mainMenuList, res.data)
        }
      })
      .catch(addCatchNotification)

  updateProfileInfo = (params: IUpdateProfileRequest) =>
    profileApi
      .updateProfileInfo(params)
      .then((res) => {
        if (res.success) {
          this.staffInfo = res.data
        }

        return res
      })
      .catch(addCatchNotification)

  setInitialParams = (params: Partial<TInitial>) => {
    this.initialParams = params
  }

  setBreadCrumb = (breadcrumb: ItemType[] | []) => {
    this.breadcrumbList = breadcrumb
  }

  reset = () => {
    this.initialParams = null
    this.breadcrumbList = []
  }
}

export const appStore = new AppStore()
