import React, { ChangeEventHandler, FocusEventHandler, useCallback, useMemo } from 'react'
import { FieldError } from 'react-hook-form/dist/types'
import { ControllerRenderProps } from 'react-hook-form/dist/types/controller'
import { FieldValues } from 'react-hook-form/dist/types/fields'

type TUseFieldsReturnValue<Element> = {
  handleChange?: ChangeEventHandler<Element>
  handleBlur?: FocusEventHandler<Element>
  helperLabel?: string
}

type TUseFieldProps<FormValues extends FieldValues, Element> = {
  field: ControllerRenderProps<FormValues>
  onChangeCallback?: ChangeEventHandler<Element>
  onBlurCallback?: FocusEventHandler<Element>
  // TODO Remove "string" after correct validation scheme
  error?: FieldError | string
  helperText?: string
  transformValue?: (value: any) => any
}

export const useFields = <FormValues extends FieldValues, Element = HTMLInputElement>({
  field,
  onChangeCallback,
  onBlurCallback,
  error,
  helperText,
  transformValue,
}: TUseFieldProps<FormValues, Element>): TUseFieldsReturnValue<Element> => {
  const handleChange = useCallback(
    (event: React.ChangeEvent<Element>) => {
      let modifiedEvent = event

      if (transformValue) {
        modifiedEvent = {
          ...event,
          target: {
            ...event.target,
            // @ts-ignore
            value: transformValue(event.target.value),
          },
        }
      }

      field.onChange(modifiedEvent)

      if (onChangeCallback) {
        onChangeCallback(modifiedEvent)
      }
    },
    [field, onChangeCallback, transformValue],
  )

  const handleBlur = useCallback(
    (event: React.ChangeEvent<Element>) => {
      field.onBlur()

      if (onBlurCallback) {
        // @ts-ignore
        onBlurCallback(event)
      }
    },
    [field, onBlurCallback],
  )

  const errorText = error && (typeof error === 'string' ? error : error?.message)

  const nextHelper = errorText || helperText

  const helperLabel = useMemo(() => nextHelper || '', [nextHelper])

  return {
    handleChange,
    handleBlur,
    helperLabel,
  }
}
